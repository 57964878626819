.container_login{
  .imageLogin{
    position: relative;
    .content{
      width: 100%;
      text-align: center;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
}
.container{
  .imageLogin{
    position: relative;
    .content{
      width: 100%;
      text-align: center;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
}