.infoApp{
    background-color: #10253F;
    padding:5px 0;
    color: white;
    padding-left: 120px;
    img{
        padding-left: 5px;
        color: white;
        width: 7,4px;
        height: 12px;
    }
}
.bg-header{
    background: url('../../asset/images/Bg-image.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &.bg-header-login {
        background: url('../../asset/images/Header_Background.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    max-width: 1920px;
    display: block;
    height: 460px;
    position: relative;
    .content{
        position: absolute;
        height: 68px;
        left: 158px;
        top: 20%;

        font-size: 50px;
        line-height: 50px;
        .lable_1{
            color: $color-woodblack;
        }
        .lable_2{
            padding-top: 10px;
            color: $color-lightblue;
        }
        .lable_3{
            padding-top: 10px;
            color: $color-woodblack;
            font-size: 30px;
            text-align: center;
        }
    }
    .search-input {
        position: absolute;
        top: 70%;
        height: 4.5rem;
        width: 868px;
        left: 161px;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        .list-box-search {
          .disable{
            pointer-events: none;
          }
          width: 100%;
          height: 59px;
          background: #FFF;
          border: 1px solid transparent;
          border-radius: 50px;
          display: block;
          &.list-box-search-mobile {
            display: none;
          }
          .MuiListItem-root {
            display: inline-block;
            width: 18.3%;
            height: 59px;
            padding: 0;
            position: relative;
            top: -8px;
            left: 19px;

            .css-2b097c-container {
              .css-z21o0a-control {
                border: none;
                border-right: 1px solid #D5D5D5;
                border-radius: 0;
                .css-1hb7zxy-IndicatorsContainer {
                  .css-tlfecz-indicatorContainer {
                    padding: 8px 16px;
                  }
                }
              }
              .css-1igyacx-control {
                box-shadow: none;
                border: none;
                border-right: 1px solid #D5D5D5;
                border-radius: 0;
                .css-1hb7zxy-IndicatorsContainer {
                  .css-1gtu0rj-indicatorContainer {
                    padding: 8px 16px;
                  }
                }
              }
            }
          }
        }
        #icon-search {
          position: relative;
          top: -3px;
          right: -35px;
          width: 20px;
          height: 20px;
        }
    }
};

.popup-search {
  overflow: scroll;
  .MuiBackdrop-root {
    background: #E5E5E5;
  }
  .form-search {
    position: relative;
    padding: 103px 26px 26px 26px;
    .title {
      display: block;
      text-align: center;
      margin-bottom: 36px;
      font-family: Open Sans;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #10253F;
    }
    .form-search-input {
      margin-bottom: 10px;
      .css-2b097c-container {
        .css-z21o0a-control {
          height: 50px;
        }
      }
    }
    .btn-general {
      margin-top: 13px;
      background: #10253F;
      border-radius: 5px;
      color: #FFF;
      text-transform: capitalize;
    }
    .disable{
      pointer-events: none;
    }
  }
  .icon-close {
    position: absolute;
    top: 51px;
    right: 33px;
  }
}

@include breakpoint(xl)   { 
    .bg-header{
        display: flex;
        justify-content: center;
        background: url('../../asset/images/Bg-1440.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &.bg-header-login {
          height: 517px;
        }
        .content{
            display: flex;
            justify-content: center;
            flex-direction: column;
            left: 265px;
            top: 150px;
        }
        .search-input {
            top: 71%;
            left: 205px;
        }
    }
    .infoApp{
       display: none !important;
    }
}

@include breakpoint(md)   {
    .bg-header{
        &.bg-header-login {
            height: 510px;
            .content{
                left: 305px;
                top: 110px;
            }
            .search-input {
                top: 248px;
                left: 78px;
            }
        }
    }
} 

@include breakpoint(xm)   { 
    .bg-header{
        background: url('../../asset/images/Bg-xs.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 295px;
        .content{
            font-size: 30px;
            line-height: 41px;
            top: 80px;
            left: 115px;
            .lable_2{
                padding-top: 0px;
            }
            .lable_3{
                padding-top: 0;
                font-size: 30px;
            }
        }
        .search-input{
        }
    }
 }
 @include breakpoint(xs)   { 
    .bg-header{
        .content{
            .lable_2{
                padding-top: 0px;
            }
            .lable_3{
                padding-top: 0;
                font-size: 20px;
            }
        }
        .search-input{
            left: 209px;
            
        }
    }
 }

@media (min-width: 1200px) and (max-width: 1281px) {
  .bg-header{
    &.bg-header-login {
        .search-input {
          left: 250px;
        }
    }
  }
}

@media (min-width: 768px) and (max-width: 945px) {
  .bg-header{
    .content {
      left: 230px !important;
    }
    .search-input {
      width: 80%;
      .list-box-search {
        .MuiListItem-root {
          width: 17.5%;
        }
      }
    }
  }
}

 @include breakpoint(mobile)   { 
    .bg-header{
        width: 414px;
        height: 233px;
        background: url('../../asset/images/Bg-mobile.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &.bg-header-login {
            height: 259px;
            background: url('../../asset/images/Bg-mobile-logged.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            .content{
                left: 13%;
                top: 21%;
            }
            .search-input {
                top: 139px;
                left: 10%;
                width: 336px;
            }
        }
        .content{
            left: 13%;
            top: 21%;
            line-height: 27px;
            font-size: 20px;
            .lable_2{
                padding-top: 0px;
            }
            .lable_3{
                padding-top: 0;
                font-size: 12px;
            }
        }
        .list-box-search {
          display: none !important;
          &.list-box-search-mobile {
            position: relative;
            display: block !important;
            input {
              position: relative;
              padding: 10px;
              margin: 0 15px;
              width: 70%;
              border: none;
              background: transparent;
              &:focus {
                outline: none;
              }
            }
            svg {
              top: 5px !important;
            }
          }
        }
    }
 }

@media (max-width: 375px) {
  .bg-header{
    .content {
      left: 35px !important;
    }
    .search-input {
      left: 22px !important;
    }
  }
}