.market-data{
  .wrap-market{
    .slick-slider{
      .slick-next{
        &:before{
          content: '' !important;
        }
      }
      .slick-prev{
        &:before{
          content: '' !important;
        }
      }
    }
  }
}