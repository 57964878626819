.layout{
    display: flex;
    flex-direction: column;
    text-align: center;
    background: #F9F9F9;
    padding: 5%;
    .subscribeQuestion{
        font-size: 35px;
        line-height: 48px;
        color: #10253F;
    }
    .subscribe{
        font-size: 18px;
        line-height: 25px;
    }
    .linkSubscribe{
        color: #FFFFFF;
        padding-top: 30px;
        .subscribeButtonLink{
            text-transform: unset;
            font-size: 16px;
            background: #10253F;
            color: #FFFFFF;
            border-radius: 50px;
            padding: 12px 80px;
            text-transform: capitalize;
        }
    }
    
}
.viewContent{
    margin-top: 15px;
}
@include breakpoint(mobile)   { 
    .layout{
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #F9F9F9;
        padding: 5%;
        .subscribeQuestion{
            font-size: 25px;
            line-height: 48px;
            color: #10253F;
        }
        .subscribe{
            font-size: 16px;
            line-height: 25px;
            padding: 0 15px;
        }
        .linkSubscribe{
            color: #FFFFFF;
            padding-top: 30px;
            .subscribeButtonLink{
                outline: none;
                font-size: 16px;
                width: 302px;
                height: 50px;
                background: #10253F;
                color: #FFFFFF;
                border-radius: 50px;
                //left: calc(50% - 302px/2);
                //bottom: 497px;
            }
        }
        
    }
 }