@mixin breakpoint($point) {
    @if $point == xl {
      @media (max-width: 1440px)  { @content ; }
    }
    @if $point == md {
      @media (max-width: 1280px)  { @content ; }
    }
    @if $point == xm {
        @media (max-width: 1199px)  { @content ; }
    }
    @if $point == xs {
      @media (max-width: 1024px)  { @content ; }
    }
    @if $point == 960 {
      @media (max-width: 960px)  { @content ; }
    }
    @if $point == mobile {
        @media (max-width: 767px) { @content ; }
    }
    @if $point == sm {
      @media (max-width: 540px) { @content ; }
    }
}