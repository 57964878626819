.container_fp_submited{
 
  .imageLogin{
    position: relative;
      .content{
        width: 100%;
        text-align: center;
        margin: auto;
        left: 0;
        right: 0;
    
      }
  }
}
