.subscription-plan {
  .title-sub {
    position: relative;
    top: -14px;
    color: #10253F;
    font-weight: 300;
    font-size: 35px;
    line-height: 48px;
    margin-bottom: 39px;
    padding-left: 16px;
  }
  .box-table-sub {
    border-bottom: 1px solid #D5D5D5;
    margin-bottom: 25px;
    padding-bottom: 22px;
    &:last-child {
      border-bottom: none;
    }
    &.box-table-sub-mobile {
      border-bottom: none;
      .MuiListItem-root {
        padding: 0;
        border-bottom: 1px solid #D5D5D5;
        padding-bottom: 11px;
        margin-bottom: 11px;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        p {
          font-size: 16px;
          line-height: 22px;
          color: #666666;
          &.sector {
            color: #000;
          }
        }
        
        .MuiCheckbox-root {
          padding: 0;
          padding-right: 10px;
          margin-left: -2px;
        }
      }
    }
    .title-sub-table {
      display: block;
      color: #10253F;
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 15px;
      padding-left: 17px;
    }
    .MuiTable-root {
      .MuiTableCell-root {
        font-family: Open Sans;
        font-size: 16px;
        line-height: 22px;
        border-bottom: none;
      }
      .MuiTableHead-root {
        .MuiTableRow-head {
          .MuiTableCell-head {
            padding: 10px 17px;
            color: #999;
            font-weight: bold;
          }
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-body {
            padding: 3px 17px;
            .MuiIconButton-root {
              &.Mui-disabled {
                pointer-events: inherit;
                &:hover {
                  cursor: no-drop;
                }
                input {
                  &:hover {
                    cursor: no-drop;
                  }
                }
              }
            }
            .link-view-data {
              color: #558ED5;
              &:hover {
                text-decoration: none;
              }
              &:focus {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  .grid-comm-mobile {
    .box-search {
      display: none;
    }
    &.list-item-mobile {
      display: none;
    }
  }
  .content-sub{
    min-height: calc(50vh + 30px);
  }
}

@include breakpoint(md) {
  .subscription-plan {
    .grid-comm-mobile {
      .box-search {
        padding-left: 17px;
        display: block !important;
      }
    }
  }
}

@include breakpoint(mobile) {
  .subscription-plan {
    .grid-comm-mobile {
      display: none;
      &.list-item-mobile {
        display: block !important;
      }
      .box-search {
        padding-left: 0;
      }
      .title-sub {
        padding-left: 0;
      }
      .box-table-sub {
        .title-sub-table {
          padding-left: 0;
        }
      }
    }
  }
}