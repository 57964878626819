.prodcut-detail{
  padding-top: 3%;
  .rootProduct{
      padding:0!important;
  }
  .marketCom {
    .marketInfo{
      .marketTitle{
        .country {
          position: relative;
          .selectCountry{
            position: absolute !important;
            right: 0 !important;
            &::after{
              top: 17px !important;
            }
          }
        }
      }
    }
  }
}