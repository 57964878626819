.category-and-countries{
  .category{
    text-align: center;
    margin-bottom: 20px;
    .btn-select-category{
      margin: 0 8px;
      color: #666666;
      &.active{
        background: rgba(16, 37, 63, 0.1);
        color: #10253F
      }
    }
  }
  .countries-menu{
    text-align: center;
    background: #F9F9F9;
    box-shadow: 0px 5px 25px #bebebe40;
    .btn-select-countries{
      border-radius: 0;
      margin: 0 8px;
      color: #999999;
      border-bottom: 1px solid #F9F9F9;
      &.active{
        border-bottom: 1px solid #10253F;
        color: #000000
      }
    }
  }
}
.wrap-market-forecast{
  padding: 20px 120px;
  .wrap-content-1{
    min-height: calc(45vh - 10px);
    padding-right: 40px;
    .filter{
      .product-select{
        float: left;
        margin-top: 20px;
        > label{
          display: none;
        }
        .select-product{
          min-width: 157px;
          margin-right: 8px;
          margin-bottom: 0;
          .css-n6yttj-singleValue{
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #10253F;
          }
        }
      }
      .date-select{
        margin-top: 20px;
        width: 320px;
        position: relative;
        float: left;
        text-align: center;
        border: 1px solid #D5D5D5;
        padding: 8px 12px;
        border-radius: 5px;
        margin-right: 3px;
        .date-data{
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #10253F;
        }
        .arrow-left{
          width: 25px;
          position: absolute;
          &:hover{
            cursor: pointer;
          }
          > img{
            width: 7.5px;
            height: 12px;
          }
        }
        .arrow-right{
          width: 25px;
          position: absolute;
          top: 9px;
          right: 12px;
          &:hover{
            cursor: pointer;
          }
          > img{
            width: 7.5px;
            height: 12px;
          }
        }
      }
      .latest{
        float: left;
        margin-top: 20px;
        .btn-latest{
          border: 1px solid #D5D5D5;
          border-radius: 5px;
          padding: 0 21px;
          min-width: 94px;
          color: #558ED5;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          text-transform: unset;
          min-height: 40px;
        }
      }
      .language-select{
        float: right;
        margin-top: 20px;
        > label{
          display: none;
        }
        .select-language{
          min-width: 157px;
          margin-bottom: 0;
          .css-n6yttj-singleValue{
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #10253F;
          }
        }
      }
      .flex-right{
        flex: auto;
        .cBwIIP {
          display: none;
        }
      }
    }
    .wrap-data{
      margin-top: 32px;
      .content{
        .title{
          font-size: 20px;
          line-height: 27px;
          color: #000000;
        }
        .title-data{
          margin-top: 15px;
          font-weight: bold;
          font-size: 16px;
          line-height: 25px;
          color: #558ED5;
        }
        .content-data{
          margin-top: 15px;
        }
        // .list-data{
        //   padding-left: 18px;
        //   font-size: 16px;
        //   line-height: 25px;
        //   color: #000000;
        // }
      }
    }
    .text-no-data{
      min-height: 300px;
    }
  }
  .nex-prev-mobile{
    display: none;
    .group-next-prev{
      margin-top: 80px;
      padding: 14px 60px;
      background: rgba(85, 142, 213, 0.1);
      border-radius: 5px;
      overflow: hidden;
      .fuzzy{
        opacity: .5;
      }
      .btn-prev{
        &:hover{
          background: unset;
        }
        color: #000000;
        font-size: 16px;
        text-transform: unset;
        .arrow-left{
          width: 7.5px;
          height: 12px;
          position: absolute;
          left: -20px;
        }
      }
      .btn-next{
        &:hover{
          background: unset;
        }
        color: #000000;
        font-size: 16px;
        float: right;
        text-transform: unset;
        .arrow-right{
          width: 7.5px;
          height: 12px;
          position: absolute;
          right: -20px;
        }
      }
    }
  }
  .wrap-content-3{
    border-right: 1px solid #D5D5D5;
    padding-right: 40px;
    .group-next-prev{
      margin-top: 80px;
      padding: 14px 60px;
      background: rgba(85, 142, 213, 0.1);
      border-radius: 5px;
      overflow: hidden;
      .fuzzy{
        opacity: .5;
      }
      .btn-prev{
        &:hover{
          background: unset;
        }
        color: #000000;
        font-size: 16px;
        text-transform: unset;
        .arrow-left{
          width: 7.5px;
          height: 12px;
          position: absolute;
          left: -20px;
        }
      }
      .btn-next{
        &:hover{
          background: unset;
        }
        color: #000000;
        font-size: 16px;
        float: right;
        text-transform: unset;
        .arrow-right{
          width: 7.5px;
          height: 12px;
          position: absolute;
          right: -20px;
        }
      }
    }
  }
  .wrap-content-2{
    padding-top: 12px;
    padding-left: 45px;
    border-left: 1px solid #D5D5D5;
  }
}
@include breakpoint(xl){
  .wrap-market-forecast{
    padding: 20px 45px;
    .wrap-content-1{
      flex-grow: 0 !important;
      max-width: 70% !important;
      flex-basis: 70% !important;
      padding-right: 0;
      .select-language{
        min-width: 157px;
        margin-bottom: 0;
        margin-left: 8px;
        float: right;
        flex: auto;
      }
    }
    .wrap-content-3{
      flex-grow: 0 !important;
      max-width: 70% !important;
      flex-basis: 70% !important;
      border-right: none;
      padding-right: 0;
    }
    .wrap-content-2{
      flex-grow: 0 !important;
      max-width: 30% !important;
      flex-basis: 30% !important;
      border-left: none;
    }
  }
}
@include breakpoint(xm){
  .wrap-market-forecast{
    .wrap-content-1{
      flex-grow: 0 !important;
      max-width: 66.666667% !important;
      flex-basis: 66.666667% !important;
      .filter{
        .flex-right{
          flex: unset;
        }
        .language-select{
          float: left;
          .select-language{
            margin-left: 0;
          }
        }
        .btn-latest{
          margin-right: 8px;
        }
      }
    }
    .wrap-content-2{
      flex-grow: 0 !important;
      max-width: 33.333333% !important;
      flex-basis: 33.333333% !important;
    }
  }
}
@include breakpoint(xs){
  .wrap-market-forecast{
    .wrap-content-3 {
        flex-grow: 0 !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
        border-right: none;
        padding-right: 0;
    }
  }
}
@include breakpoint(mobile){
  .category-and-countries{
    .category{
      padding: 10px 0;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin-bottom: 12px;
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(68, 67, 67, 0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar{
        height: 3px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #000000;
        border: 2px solid #555555;
      }
    }
    .countries-menu{
      padding: 10px 0 !important;
      background: #F5F5F5;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(68, 67, 67, 0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar{
        height: 3px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #000000;
        border: 2px solid #555555;
      }
    }
  }
  .wrap-market-forecast{
    .wrap-content-1{
      flex-grow: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
      .filter{
        .date-select{
          margin-top: 20px;
          width: 100%;
        }
        .product-select{
          width: 100%;
          .select-product{
            margin-right: 0;
          }
        }
        .select-date{
          width: 100%;
        }
        .btn-latest{
          display: none;
        }
        .select-language{
          display: none;
        }
      }
      .wrap-data{
        margin-top: 24px;
      }
    }
    .wrap-content-3{
      display: none;
    }
    .nex-prev-mobile{
      display: block;
      .group-next-prev{
        padding: 14px 40px;
      }
    }
    .wrap-content-2{
      flex-grow: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
      margin-top: 50px;
      padding-left: 0;
    }
  }
}