.account {
    margin: 0 auto;
    padding: 51px 0;
    display: block;
    .MuiBox-root {
        padding-top: 13px;
    }
    .MuiTypography-root {
        margin-top: 51px;
    }
    .MuiAppBar-root {
        box-shadow: none;
        background-color: #FFF;
        max-width: 600px;
        margin: 0 auto;
        .MuiTabs-flexContainer {
            .MuiTab-textColorPrimary {
                color: #999999;
                &.Mui-selected {
                    color: #10253F;
                }
            }
            .MuiButtonBase-root {
                &:first-child {
                    //width: 40%;
                    width: 100%;
                    min-width: 100%;
                }
                &:last-child {
                    //width: 60%;
                    //min-width: 60%;
                }
                padding: 0;
                text-transform: capitalize;
                font-weight: 300;
                font-family: Open Sans;
                font-size: 35px;
                line-height: 48px;
            }
        }
        .MuiTabs-indicator {
            background-color: #FFF;
        }
    }
    
    .otp {
        padding: 10px 24px !important;
        .otp-title {
            margin-bottom: 22px;
            h3 {
                font-size: 25px;
                font-weight: 300;
                line-height: 34px;
                margin-bottom: 8px;
            }
            p {
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .btn-user-profile {
            margin-top: 2px;
        }

        .otp-noti {
            text-align: center;
            margin-top: 25px;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            span {
                color: #666666;
                margin-right: 12px;
            }
            strong {
                color: #10253F;
            }
        }
    }
}

#user-profile {
    .paper-profile {
        box-shadow: none;
        padding: 0;
    }
    .image-profile {
        position: relative;
        text-align: center;
        .MuiAvatar-root {
            margin: 0 auto;
            width: 113px;
            height: 113px;
            margin-bottom: 6px;
            background: #D5D5D5;
            opacity: 0.65;
            position: relative;
            cursor: pointer;
            &.avatar-exists {
                opacity: 1;
                .MuiAvatar-img {
                    display: block;
                }
            }
            .MuiAvatar-img {
                display: none;
            }
        }
        .icon-camera {
            width: 21px;
            height: 17px;
            position: absolute;
            top: 47px;
            color: #D5D5D5;
            opacity: 0.1;
        }
        .image-note {
            color: #D5D5D5;
            line-height: 19px;
        }
    }
    .MuiFormControl-root {
        margin: 11px 0 !important;
        .MuiInputLabel-formControl {
            font-size: 19px;
            line-height: 19px;
            color: #333;
        }
        .MuiInput-formControl {
            font-size: 1em;
            line-height: 19px;
            height: 50px;
            border: 1px solid #D5D5D5;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: 20px;
            background: #FFF;
            .MuiInput-input {
                padding: 0 18px;
                height: 100%;
                color: #333;
            }
            &.Mui-error {
                border: 1px solid #f44336;
            }
            &:before {
                border-bottom: none;
            }
            &:after {
                border-bottom: none;
            }
        }
    }
    .user-phone-number {
        position: relative;
        .form-select-input {
            width: 110px;
            height: 50px;
            position: absolute;
            margin-bottom: 0;
            z-index: 999;
            bottom: 1px;
            left: 1px;
            & > div {
                height: 100%;
                & > div {
                    height: 100% !important;
                    border: none !important;
                    box-shadow: 0 0 0 1px transparent;
                    z-index: 999;
                    & > div {
                        background-color: #FFF;
                        &.css-4ljt47-MenuList {
                            box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
                        }
                    }
                }
            }
            
        }
        .text-phone {
            margin: 0 !important;
            padding-left: 110px;
            border: 1px solid #D5D5D5;
            border-radius: 5px;
            .MuiInput-formControl {
                margin-top: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: none !important;
                .MuiInput-input {
                    border-left: 1px solid #D5D5D5;
                    height: auto;
                    border-radius: 0;
                }
            }
        }
    }
    .btn-user-profile {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-transform: capitalize;
        color: #FFFFFF;
        background: #10253F;
        border-radius: 5px;
        padding: 14px 0;
    }
    .user-logout {
        text-align: center;
        margin-top: 24px;
        line-height: 22px;
        font-size: 14px;
        cursor: pointer;
        @media (max-width: 1279px) {
            display: none;
        }
    }
    .title-card {
        padding-bottom: 5px;
        color: #10253F;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-align: justify;

    }
    .box-Payment {
        background: rgba(16, 37, 63, 0.1);
        border-radius: 5px;
        margin-bottom: 30px;
        box-shadow: none;
        &:before {
            background-color: transparent;
        }
        .MuiGrid-root {
            position: relative;
        }
        .card-info {
            p {
                font-size: 20px;
                line-height: 27px;
                &:last-child {
                    color: #558ED5;
                    cursor: pointer;
                    height: 26.5px;
                }
            }
        }
        .btn-edit {
            position: absolute;
            right: 52px;
            top: 46px;
            font-weight: bold;
            cursor: pointer;
        }
        .box-card {
            width: 275px;
            float: left;
            margin-right: 10px;
            margin-bottom: 10px;
            &.submit-error {
                .StripeElement--empty {
                    border: 1px solid #f44336;
                }
                .MuiInput-formControl {
                    border: 1px solid #f44336;
                }
            }
            &.box-card-number {
                width: 242px;
            }
            &.box-expiry {
                width: 92px;
            }
            &.box-cvc {
                width: 70px;
            }
            label {
                display: block;
                margin-bottom: 5px;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
            }
            .MuiTextField-root {
                margin: 0 !important;
                .MuiInput-formControl {
                    margin: 0;
                }
            }
            .StripeElement {
                background: #FFFFFF;
                border: 1px solid #D5D5D5;
                box-sizing: border-box;
                border-radius: 5px;
                height: 50px;
                padding: 18px 18px;
            }
        }
    }
    .payment-set-default {
        float: left;
        width: 100%;
        .radio-default {
            .MuiFormControlLabel-label {
                margin-top: 0;
            }
            svg {
                fill: #333;
            }
        }
    }
    
    .btn-remove-card {
        color: #999999;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        background: transparent;
    }
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@include breakpoint(xl)   { 
}

@include breakpoint(mobile) {
    
    .account {
        padding: 20px 0;
        .MuiAppBar-root {
            .MuiTabs-flexContainer {
                .MuiButtonBase-root {
                    font-size: 25px;
                }
            }
        }
        .box-Payment {
            .btn-edit {
                top: 37px !important;
                right: 25px !important;
            }
        }
        .btn-remove-card {
            top: 0 !important;
        }
    }
}