@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');
@import './util/breakpoint';
@import "variables";

@import "custom";
@import "../../components/HeaderBackgound/style.scss";
@import "../../components/Footer/style.scss";
@import "../../components/Header/style.scss";
@import "../../components/MarketData/style.scss";
@import "../../components/LayoutMarketForecast/style.scss";
@import "../../components/LayoutMarketComm/style.scss";
@import "../../components/LayoutSubscribe/style.scss";
@import "../../components/About/style.scss";
@import "../../components/Cookie/style.scss";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import "../../components/Login/style.scss";
@import "../../components/ForgetPassword/Default/style.scss";
@import "../../components/ForgetPassword/Submited/style.scss";
@import "../../components/SignUp/style.scss";
@import "../../components/LatestCommentaries/style.scss";
@import "../../components/GotoSubscribe/style.scss";
@import "../../components/MarketDataDetail/style.scss";
@import "../../components/AnalysisProductDetail/style.scss";
@import "../../components/FormSelectProductDetail/style.scss";
@import "../../components/Analysis/style.scss";
@import "../../components/ProductDetail/style.scss";
@import "../../components/Term/style.scss";
@import "../../components/UserProfile/style.scss";
@import "../../components/SubscriptionPlan/style.scss";
@import "../../components/ResetPassword/style.scss";
@import "../../components/ProductionInfo/style.scss";
@import "../../components/Chart/style.scss";
@import "../../components/SubscribePopup/style.scss";
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  // / IE10+ specific styles go here / 
  @import "./IE_styles.scss"; 
  @import "../../components/HeaderBackgound/IE_style.scss";
  @import "../../components/ProductDetail/IE_style.scss";
  @import "../../components/SignUp/IE_style.scss";
  @import "../../components/ForgetPassword/Default/IE_style.scss";
  @import "../../components/ForgetPassword/Submited/IE_style.scss";
  @import "../../components/ResetPassword/IE_style.scss";
  @import "../../components/LayoutMarketForecast/IE_style.scss";
  @import "../../components/MarketData/IE_style.scss";
  .btn-latest{
    padding-top: 9px!important;
    font-family: $font-opensans!important;
    text-transform: capitalize !important;
  }
}
