.account-box-logo {
    padding-top: 127px;
    .account-logo {
        display: inline-block;
        &.account-logo-efeed {
            padding-right: 19px;
            border-right: 1px solid #E3E3E3;
            cursor: pointer;
        }

        &.account-logo-spout {
            padding-left: 20px;
            cursor: pointer;
        }
    }
}

.container_login{
    height: 100vh;
    .form{
        min-height: 100vh !important;
        display: flex;
        justify-content: center;
        .contentLeft{
            position: relative;
            .logoLogin{
                margin-top: 15%;
                background: url('../../asset/images/Web-logo-2.png');
                width: 184px;
                height: 32px;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            .formLogin{
                padding-top: 4.6em !important;
                font-weight: 300;
                font-size: 25px;
                padding-bottom: 32%;
               form{
                    padding-top: 20px;
                    .btn{
                        width: 100%;
                        color: white;
                        background: #10253F;
                        border-radius: 5px;
                        margin-top: 2%;
                        text-transform: capitalize;  
                    }
               }
                .linkLogin{
                    padding-top: 9em;
                    font-size: 15px;
                    .MuiGrid-root {
                        &:first-child {
                            margin-bottom: 16px;    
                        }
                    }
                    a{
                        text-decoration: none;
                        font-size: 15px;
                        color: #10253F!important;
                        font-weight: 600;
                    }
                }
                
            }

            .CopyrightLogin{
                position: absolute !important;
                font-size: 14px;
                color: #999999;
                bottom: 3%;
            }
        }
        
    }
    .imageLogin{
        min-height: 100vh !important;
        background: url('../../asset/images/Bg1.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
        display: flex;
        justify-content: center;
        .content{
     
            position: absolute;
            height: 68px;
            //left: 158px;
            top: 20%;
    
            font-size: 50px;
            line-height: 50px;
            .lable_1{
                color: $color-woodblack;
            }
            .lable_2{
                padding-top: 10px;
                color: $color-lightblue;
            }
            .lable_3{
                padding-top: 10px;
                color: $color-woodblack;
                font-size: 30px;
                text-align: center;
            }
        }
    }
}

@include breakpoint(xl)   { 
    .container_login{
        height: 100vh;

        .form{
            min-width: 50%!important;
            .contentLeft{
                padding-left: 35px;
                .logoLogin{
                    //left: 115px;
                    top: 30px;
                }
                .formSignUp{
                    top: 60px;
                }
                .Copyright{
                    padding-top: 15%;
                    font-size: 14px;
                    color: #999999;
                    position: absolute;
                }
                .linkSignup{
                    top: 830px;
                    a{
                        color:black;
                        text-decoration: none;
                    }
                }
                
            }
        }
        .imageLogin{
            max-width: 50%!important;
            min-width: 50%!important;
            background-position: 66% 0%;
            background-repeat: no-repeat;
            object-fit: cover;
            .content{
                font-size: 35px;
                .lable_3{
                    font-size: 20px;
                }
            }
        }
    }
}

@include breakpoint(xm)   { 
    .container_login{
        .form{
            min-width: 50%!important;
            .contentLeft{
                padding-left: 25px;
            }
        }
        .imageLogin{
            max-width: 50%!important;
            min-width: 50%!important;
            background-position: 67% 60%;
            background-repeat: no-repeat;
            object-fit: cover;
        }
    }
}

@include breakpoint(xs)   { 
    .container_login{
        .form{
            min-width: 100%!important;
            .contentLeft{
                padding:0 150px;
                .logoLogin{
                }
                .formSignUp{
                    top: 60px;
                }
                .Copyright{
                    padding-top: 23%;
                }

                
            }
        }
        .imageLogin{
            display: none;
        }
    }
}

@media (min-width: 1440px) and (max-width: 1499px) {
    .account-box-logo {
        .account-logo {
            &.account-logo-spout {
                padding-left: 7px;
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1074px) {
    .account-box-logo {
        .account-logo {
            &.account-logo-spout {
                padding-left: 8px;
            }
        }
    }
}

@include breakpoint(mobile)   {
    .account-box-logo {
        padding-top: 62px;
    }
    .container_login{
        height: 120px;
        .form{
            min-width: 100%!important;
            .contentLeft{
                padding:0px 15px;
                .formLogin {
                    padding-top: 3.2em !important;
                    padding-bottom: 104px !important;
                    .linkLogin{ 
                        padding-top: 4.2em !important;
                    }
                }
                .CopyrightLogin {
                    position: relative !important;
                    text-align: center;
                    bottom: 0 !important;
                    padding-bottom: 30px;
                }
                .logoLogin{
                    top: 52px;
                    left: 20px;
                }
                .formSignUp{
                    top: 80px;
                    width: 340px;
                        input{
                            width: 310px;
                        }
                        button{
                            width: 335px;
                        }
                        .makeStyles-inputContainer-131{
                            width: 335px;
                        }
                        .linkSignup{
                            padding-top: 10px;
                           
                        }
                }
                
            }
        }
        .imageLogin{
            display: none;
        }
    }
}