.rs-picker-menu{
    z-index: 9999999;
}

.chart {
    overflow: hidden;
}

.rounded_chart{
    .infoVitamin{
        .modalAndVitamin{
            .modalProduct{
                display: flex;
                padding-top: 10px;
                justify-content: flex-end;
            }
            .title{
                // min-width: 60%;
            }
        }
        
        .vitamin{
            font-size: 35px;
            line-height: 48px;
            color: #10253F;
        }
        .currency{
            font-size: 16px;
            line-height: 22px;
            color: #999999;
        }
    }
    .number{
        .numberLeft{
            font-weight: bold;
            font-size: 45px;
            line-height: 61px;
            color: #10253F;
            .fluctuations{
                &.increase{
                  margin-left: 10px;
                  position: relative;
                  color: #2FCAAE;
                  &::before{
                    content: '';
                    position: absolute;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 15px solid #2FCAAE;
                    left: 0px;
                    top: 20px;
                  }
                }
              }
              .fluctuations{
                &.decrease{
                  margin-left: 10px;
                  position: relative;
                  color:red;
                  &::before{
                    content: '';
                    position: absolute;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 15px solid red;
                    left: 0px;
                    top: 20px;
                  }
                }
              }
        }
        .numberRight{
            padding-top: 10px;
            color: #666666;
            font-size: 16px;
            line-height: 22px;
            .previous{
                line-height: 22px;
                padding-right: 40px;
                
            }
            .fluc{
                line-height: 22px;
                padding-right: 21px;
            }
            .fluctuations{
                &.increase{
                  margin-left: 10px;
                  position: relative;
                  color: #2FCAAE;
                  &::before{
                    content: '';
                    position: absolute;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 5px solid #2FCAAE;
                    left: 10px;
                    top: 8px;
                  }
                }
              }
              .fluctuations{
                &.decrease{
                  margin-left: 10px;
                  position: relative;
                  color: red;
                  &::before{
                    content: '';
                    position: absolute;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid red;
                    left: 10px;
                    top: 8px;
                  }
                }
              }
        }
    }
    .exportPrice{
        .time{
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #999999;
        }
        .country{
            font-size: 16px;
            line-height: 22px;
            color: #000000;
        }
        .export{
            font-size: 16px;
            line-height: 22px;
            color: #999999;
        }
    }
    .datetime{
        padding-top:30px;
        a{
            height: 40px;
            span{
                vertical-align: middle;
            }
        }
        .btnChart{
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            .plus_and_min{
                position: relative;
                width: 80px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #D5D5D5;
                box-sizing: border-box;
                border-radius: 5px;
                .line{
                    position: absolute;
                    transform: rotateX(45deg);
                    background-color: #D5D5D5;
                    width: 1px;
                    height: 100%;
                    left: 49%;
                    z-index: 99999;
                }
                .plus{
                    color: #666666;
                    font-size: 16px;
                    padding: 7px 0px 6px 19px;
                }
                .mins{
                    color: #666666;
                    font-size: 16px;
                    padding: 7px 0px 6px 15px;
                }
            }
            .zoomm{
                margin-left: 15px;
                position: relative;
                width: 40px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #D5D5D5;
                box-sizing: border-box;
                border-radius: 5px;
                img{
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    top:13px;
                    left: 13px;
                }
            }
        }
       
    }
    .noteChart{
        // display: flex;
        padding-top:15px;
        .squareCountry{
            background-color:  #558ED5;
            width: 14px;
            height: 14px;
            margin:  2px 10px 10px 10px;
        }
        .Country{
            font-weight: bold;
            font-size: 12px;
            color: #558ED5;
        }
        .squareProvince{
            background-color:  #00E6AC;
            width: 14px;
            height: 14px;
            margin:  2px 10px 10px 10px;
        }
        .Province{
            font-weight: bold;
            font-size: 12px;
            color: #00E6AC;
        }
        .squareCity{
            background-color:  #F9D649;
            width: 14px;
            height: 14px;
            margin:  2px 10px 10px 10px;
        }
        .City{
            font-weight: bold;
            font-size: 12px;
            color: #F9D649;
        }
    }
    .btnChartData{
        cursor: pointer;
        display: none;
        .plus_and_min{
            position: relative;
            width: 80px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #D5D5D5;
            box-sizing: border-box;
            border-radius: 5px;
            .line{
                position: absolute;
                transform: rotateX(45deg);
                background-color: #D5D5D5;
                width: 1px;
                height: 100%;
                left: 49%;
                z-index: 99999;
            }
            .plus{
                color: #666666;
                font-size: 16px;
                padding: 7px 0px 6px 19px;
            }
            .mins{
                color: #666666;
                font-size: 16px;
                padding: 7px 0px 6px 15px;
            }
        }
        .zoomm{
            margin-left: 55%;;
            position: relative;
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #D5D5D5;
            box-sizing: border-box;
            border-radius: 5px;
            img{
                position: absolute;
                width: 13px;
                height: 13px;
                top:13px;
                left: 13px;
            }
        }
    }
    .container{
        padding: 3% 5% 3% 5%;
       
    }
   
}
@include breakpoint(mobile) {
    .rounded_chart{
        .noteChart{
            // display: flex;
            padding-top:15px;
            flex-wrap: unset!important;
            .squareCountry{
                background-color:  #558ED5;
                width: 14px;
                height: 14px;
                margin:  2px 10px 10px 10px;
            }
            .Country{
                font-weight: bold;
                font-size: 12px;
                color: #558ED5;
            }
            .squareProvince{
                background-color:  #00E6AC;
                width: 14px;
                height: 14px;
                margin:  2px 10px 10px 10px;
            }
            .Province{
                font-weight: bold;
                font-size: 12px;
                color: #00E6AC;
            }
            .squareCity{
                background-color:  #F9D649;
                width: 14px;
                height: 14px;
                margin:  2px 10px 10px 10px;
            }
            .City{
                font-weight: bold;
                font-size: 12px;
                color: #F9D649;
            }
        }
        .btnChartData{
            cursor: pointer;
            display: flex;
            .plus_and_min{
                position: relative;
                width: 80px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #D5D5D5;
                box-sizing: border-box;
                border-radius: 5px;
                .line{
                    position: absolute;
                    transform: rotateX(45deg);
                    background-color: #D5D5D5;
                    width: 1px;
                    height: 100%;
                    left: 49%;
                    z-index: 99999;
                }
                .plus{
                    color: #666666;
                    font-size: 16px;
                    padding: 7px 0px 6px 19px;
                }
                .mins{
                    color: #666666;
                    font-size: 16px;
                    padding: 7px 0px 6px 15px;
                }
            }
            .zoomm{
                margin-left: 55%;
                position: relative;
                width: 40px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #D5D5D5;
                box-sizing: border-box;
                border-radius: 5px;
                img{
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    top:13px;
                    left: 13px;
                }
            }
        }
        .container{
            padding: 3% 5% 3% 5%;
           
        }
       
    }
}
div[role=tooltip] {
    z-index: 999999;
} 