.content-subscrise{
  max-width: 1100px;
  padding: 40px 0;
  margin: auto;
  text-align: center;
  .title-subscribe{
    font-weight: bold;
    font-size: 35px;
    line-height: 48px;
    color: #10253F;
    box-shadow: none;
  }
  .subtitle{
    box-shadow: none;
    max-width: 800px;
    margin: auto;
    padding: 10px;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #10253F;
  }
  .wrap-icons{
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .ellipse{
      padding: 20px 35.5px;
      &:last-child{
        padding-left: 5px !important;
      }
      .icon-subs{
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(16,37,63, 0.1);
        border-radius: 50%;
        margin: auto;
      }
      .text-icon{
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        box-shadow: none;
        color: #000000;
        margin-top: 20px;
      }
    }
  }
  .contactButton{
    font-size: 16px;
    min-width: 410px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    color: #FFFFFF !important;
    background: #10253F !important;
    border-radius: 25px !important;
    text-transform: unset !important;
    box-shadow: none;
    &:hover{
      box-shadow: none;
    }
  }
  .wrap-btn-contact{
    margin: 80px 0 140px 0;
  }
}
.wrap-faq{
  padding: 20px 0;
  text-align: center;
  width: 100% !important;
  max-width: 100%;
  background: #F9F9F9;
  .title-faq{
    font-weight: 300;
    font-size: 25px;
    line-height: 34px;
    color: #10253F;
    box-shadow: none;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .faq-item{
    background: rgba(16,37,63, 0.1);
    padding: 12px 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    max-width: 880px;
    border-radius: 4px;
    font-size: 20px;
    line-height: 27px;
    text-align: justify;
    color: #10253F;
  }
}
@include breakpoint(xs) {
  .content-subscrise{
    .title-subscribe{
      font-size: 25px;
    }
    .subtitle{
      font-size: 15px;
    }
    .wrap-icons{
      padding: 0 163px;
      .ellipse{
        &:last-child{
          padding: 40px;
        }
        padding: 40px;
      }
      flex-wrap: wrap;
    }
    .faq-item{
      text-align: left;
    }
  }
  .mb-pd{
    padding: 60px;
  }
}
@include breakpoint(sm) {
  .content-subscrise{
    .wrap-icons{
      .ellipse{
        padding: 25px 40px;
        &:last-child{
          padding-left: 40px !important;
        }
      }
    }
    .wrap-btn-contact{
      .contactButton{
        min-width: 310px !important;
      }
    }
  }
  .mb-pd{
    padding: 40px;
  }
}