.wrap-market-forecast{
  .wrap-content-3{
    .group-next-prev{
      margin-top: 80px;
      padding: 14px 60px;
      background: rgba(85, 142, 213, 0.1);
      border-radius: 5px;
      overflow: hidden;
      .fuzzy{
        opacity: .5;
      }
      .btn-prev{
        &:hover{
          background: unset;
        }
        color: #000000;
        font-size: 16px;
        text-transform: capitalize !important;
        .arrow-left{
          width: 7.5px !important;
          height: 12px !important;
          position: absolute !important;
          left: -20px !important;
          margin: auto !important;
          top: 0 !important;
          bottom: 3px !important;
        }
      }
      .btn-next{
        &:hover{
          background: unset;
        }
        color: #000000;
        font-size: 16px;
        float: right;
        text-transform: capitalize !important;
        .arrow-right{
          width: 7.5px;
          height: 12px;
          position: absolute;
          right: -20px;
          margin: auto !important;
          top: 0 !important;
          bottom: 2px !important;
        }
      }
    }
  }
}
@include breakpoint(mobile){
  .wrap-market-forecast{
    .nex-prev-mobile{
      .btn-prev{
        .arrow-left{
          width: 7.5px !important;
          height: 12px !important;
          position: absolute !important;
          left: -20px !important;
          margin: auto !important;
          top: 0 !important;
          bottom: 3px !important;
        }
      }
      .btn-next{
        .arrow-right{
          width: 7.5px;
          height: 12px;
          position: absolute;
          right: -20px;
          margin: auto !important;
          top: 0 !important;
          bottom: 3px !important;
        }
      }
    }
  }
}
