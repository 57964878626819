.paper {
  text-align: center;
  position: absolute;
  width: 500px;
  height: 360px;
  background: #FFFFFF;
  padding: 40px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .wrap-logo{
    display: flex;
    justify-content: center;
    .logo-item{
      cursor : pointer;
      &:first-child {
        width: 116px;
        max-height: 37px;
        margin-right: 40px;
      }
      &:last-child{
        width: 177px;
        max-height: 37px;
      }
    }
  }
  .title{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-top: 33px;
    margin-right: auto;
    margin-left: auto;
    max-width: 275px;
  }
  .subscription{
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    margin-top: 12px;
    margin-right: auto;
    margin-left: auto;
    max-width: 300px;
  }
  .btn-subscribe{
    background: #10253F;
    border-radius: 50px;
    width: 240px;
    height: 38px;
    color: #FFFFFF;
    margin-top: 34px;
    &:hover{
      background: #10253F;
    }
  }
  .btn-close{
    text-transform: unset;
    &:hover{
      background: unset;
    }
  }
}
@include breakpoint(mobile){
  .paper {
    max-width: 90%;
  }
  .wrap-logo{
    display: flex;
    justify-content: center;
    .logo-item{
      &:first-child {
        max-width: 90%;
        margin-right: 20px !important;
      }
      &:last-child{
        max-width: 90%;
      }
    }
  }
}