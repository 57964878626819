.market-data{
  padding: 20px 0 40px 0;
  background: #FAFAFA;
  .wrap-market{
    margin: auto;
    padding: 0 6%;
    .link-market{
      font-size: 25px;
      line-height: 34px;
      color: #558ED5 !important;
      text-decoration: none;
      margin-top: 13px;
      margin-bottom: 48px;
    }
    .MuiPaper-root {
      background: #FAFAFA;
      box-shadow: none;
    }
    .MuiPaper-rounded{
      border-radius: 0;
    }
    .slick-slider{
      text-align: center;
      position: relative;
      padding: 0 20px;
      cursor: pointer;
      .slick-prev{
        background: url('../../asset/images/arrow_left.png');
        background-repeat: no-repeat;
        width: 12px;
        height: 19px;
        left: 0 !important;
        &:before{
          content: unset;
        }
      }
      .slick-next{
        background: url('../../asset/images/arrow_right.png');
        background-repeat: no-repeat;
        width: 12px;
        height: 19px;
        right: 0 !important;
        &:before{
          content: unset;
        }
      }
      .slide-item{
        max-width: 70%;
        margin: auto;
        text-align: left;
        word-break: break-word;
        .MuiPaper-elevation1{
          box-shadow: none;
        }
        .title-market{
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.05em;
        }
        .content-market{
          color: #000000;
          font-size: 16px;
        }
        .cost{
          position: absolute;
          bottom: 0;
          margin-top: 20px;
          display: flex;
          .increase{
            margin-left: 10px;
            position: relative;
            color: #2FCAAE;
            &::after{
              content: '';
              position: absolute;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid #2FCAAE;
              right: -15px;
              top: 8px;
            }
          }
          .decrease{
            margin-left: 10px;
            color: #B64A4A;
            position: relative;
            &::after{
              content: '';
              position: absolute;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #B64A4A;
              right: -15px;
              top: 8px;
            }
          }
        }
      }
    }
    .slick-slide{
      min-height: 100px;
      &.slick-active{
        &.slick-current{
          border-left: 0 !important;
        }
      }
      &[aria-hidden]{
        border-left: 1px solid #D5D5D5;
      }
    }
    .slick-list{
      .slick-track{
        display: flex !important;
      }
    }
  }
}

@include breakpoint(xl) { 
  
}
@include breakpoint(mobile) {
  .market-data{
    .wrap-market{
      padding: 0 20px;
      .slick-slider{
        .slide-item{
          width: auto !important;
        }
        // .slick-slide{
        //   &:not(.slick-cloned){
        //     border-right: 0;
        //   }
        // }
      }
    }
  }
}