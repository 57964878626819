.market-data-detail {
  padding: 40px 120px;
  min-height: calc(100vh - 476px);
  box-sizing: border-box;
  .subHeaderMarketComm{
    font-size: 20px !important;
    line-height: 27px !important;
    color: #666666 !important;
    margin-bottom: 21px;
    position: relative !important;
  }

  .marketCommTitle{
    font-size: 35px;
    line-height: 48px;
    color: #10253F;
    line-height: 25px;
    margin-bottom: 52px;
    position: relative !important;
    z-index: 0;
    span {
      display: block;
    }
  }
  .link-market{
    text-decoration: none;
    font-size: 16px !important;
    line-height: 22px;
    color: #000000 !important;
  }
  .Mui-selected{
    .link-market{
      color: #558ED5 !important;
    }
  }
  .market-data {
    background-color: white;
    min-height: calc(50vh + 30px);
    .MuiListItem-root {
      min-height: 74px;
      padding-right: 45px;
    }
    @media (width: 1280px) {
      flex-grow: 0;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }
  
  .box-text {
    width: 70%;
    .text-sub-market{
      font-size: 16px;
      line-height: 22px;
      .sector {
        margin-right: 12px;
      }
      .content {
        padding-right: 10px;
      }
      .price {
        float: right;
      }
    }
  }

  .box-date {
    width: 22%;
  }
  .date-comm{
    width: 100%;  
    text-align: left;
    font-size: 16px;
    line-height: 18px;
  }

  .box-price {
    width: 10%;
    text-align: right;
    strong {
      position: relative;
      .MuiSvgIcon-root {
        position: absolute;
        right: -22px;
        bottom: -3px;
        color: #00E6AC;
        &.decrease {
          color: red;
        }
      }
    }
  }
  
  .select-filter-mobile{
    display: none;
  }
  .loader {
    position: relative;
    min-height: 50px;
    .load-more{
      position: absolute;
      left: 45%;
      margin: 5px 0;
      color: #558ED5;
    }
  }
  

  .filter-md {
    display: none;
    margin-bottom: 46px;
    .box-search {
      width: 49%;
      display: inline-block;
      &:first-child {
        margin-right: 18px;
        @media (width: 1280px){
          margin-right: 16px !important;
        }
        @media (min-width: 988px) and (max-width: 1005px){
          margin-right: 16px !important;
        }
        @media (min-width: 588px) and (max-width: 605px){
          margin-right: 9px !important;
        }
      }
      .sc-fzoLsD {
        margin-bottom: 0px;
      }
    }
    .MuiInputBase-root {
      width: 100%;
    }
    .MuiInput-underline {
      &::before {
        border-bottom: none !important;
      }
      &::after {
        border-bottom: none !important;
      }
    }
    .MuiFormLabel-root {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 5px;
    }
    .MuiSelect-root {
      background-color: #FFF;
      border: 1px solid #D5D5D5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 13px 24px;
    }
    
  }
  .text-no-data{
    position: absolute;
    left: calc(45% - 35px);
    top: 200px;
    width: auto;
    padding: 0;
    .no-data{
      font-size: 20px;
      line-height: 27px;
      color: #999999;
    }
  }
}

@include breakpoint(sm) {
  .market-data-detail {
    padding: 32px 28px !important;
    .marketCommTitle {
      padding: 0 !important;
    }
    .search-data {
      top: 0 !important;
      margin-top: 35px;
      margin-bottom: 21px;
      left: 0;
      float: left;
    }
    .MuiListItem-root {
      display: block;
      .box {
        width: 100%;
        text-align: left;
        &.box-date {
          opacity: 0.5;
        }
      }
    }
  }
  .filter-md {
    display: block !important;
    .box-search {
      width: 100% !important;
      &:first-child {
        margin-right: 0 !important;
        margin-bottom: 8px;
      }
    }
  }
  .MuiGrid-grid-md-3 {
    display: none;
  }
}

@include breakpoint(md) {
  .market-data-detail {
    padding: 40px 45px;
    .search-data {
      top: -8px;
    }
    .filter-md {
      display: block !important;
      padding: 0px !important;
    }
  }
  .MuiGrid-grid-md-3 {
    display: none;
  }
}

@include breakpoint(xl) { 
  .market-data-detail{
    .wrap-market{
      max-width: 90%;
    }
  }
}

@media (min-width: 589px) and (max-width: 988px) {
  .market-data-detail {
    .filter-md {
      .box-search {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (min-width: 493px) and (max-width: 588px) {
  .market-data-detail {
    .marketCommTitle {
      padding: 0;
    }
    .filter-md {
      .box-search {
        width: 100% !important;
      }
    }
    .search-data {
      top: 0 !important;
      margin-top: 35px;
      margin-bottom: 21px;
      left: 0;
      float: left;
    }
  }
}

@include breakpoint(mobile) {
  .market-data-detail{
    .wrap-market{
      max-width: 90%;
      .slick-slider{
        max-width: 85%;
        margin: auto;
        .slick-slide{
          &:not(.slick-cloned){
            border-right: 0;
          }
        }
      }
    }
    .text-no-data{
      position: unset;
      left: 0;
      top: 0;
      width: auto;
      padding: 0 !important;
      justify-content: center;
      text-align: center;
    }
  }
}

.search-data {
  float: right;
  position: relative;
  top: -10px;
  right: -15px;
  .search-input {
    border: 1px solid #D5D5D5;
    border-radius: 50px;
    max-width: 246px;
    padding: 13px 48px 13px 23px;
    font-size: 16px;
    line-height: 18px;
    &:focus {
      outline: none;
    }
  }
  .MuiSvgIcon-root {
    position: absolute;
    right: 21px;
    top: 11px;
    color: #D5D5D5;
  }
}
