.footer {
    background: #10253F;
    color: #FFF;
    padding: 8em 6em 2em 6em;
    .logoFooter {
        display: flex;
        .logoFooter-box {
            display: flex;
            div {
                cursor: pointer; 
                padding-top: 50px;
                &:first-child {
                    position: relative;
                    top: -5px;
                    margin-right: 59px;
                    .logan{
                        display: block;
                        margin-top: 7px;
                        font-size: 10px;
                        line-height: 14px;
                        color: #FAFAFA;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
    .contact {
        .MuiListItem-gutters {
            padding: 0;
            text-align: center;
            cursor: pointer;
            display: inline-block;
            width: 25%;
            &:last-child {
                text-align: right;
            }
            .MuiListItemText-root {
                margin: 0;
                .MuiListItemText-primary {
                    font-size: 18px;
                    line-height: 25px;
                }
                a {
                    color: #FFF;
                    display: block;
                    width: 100%;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .spounLogo {
        margin-bottom: 34px;
        position: relative;
        &.footer-content {
            padding: 0;
            margin: 0;
            p {
                font-size: 12px;
                line-height: 16px;
            }
            .footer-content-left {
                p {
                    width: 81%;
                    color: #FAFAFA;
                    margin-top: 19px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
            .footer-content-right {
                text-align: right;
                margin-top: 10px;
                .content {
                    margin-left: 10em;
                    span {
                        color: #FFF;
                        font-size: 14px;
                        line-height: 19px;
                        margin-bottom: 19px;
                        display: block;
                    }
                    p {
                        opacity: 0.5;
                        a {
                            color: #FFF;
                            text-decoration: underline;
                        }
                    }
                    .text-foot{
                        max-width: 500px;
                        float: right;
                    }
                }
                
            }
        }
    }
}

@include breakpoint(md) {
    .footer{
        .logoFooter {
            .logoFooter-box {
                div {
                    &:first-child {
                        margin-right: 29px;
                    }
                    img {
                        &:first-child {
                            width: 134px;
                            height: 34px;
                        }
                        &:last-child {
                            width: 167px;
                            height: 34px;
                        }
                    }
                }
            }
        }

        .spounLogo {
            &:first-child {
                margin-bottom: 14px;
            }
            &.footer-content {
                .footer-content-right {
                    .content {
                        margin-left: 8em;
                    }
                }
            }
            .contact {
                .MuiListItem-gutters {
                    text-align: right;
                    padding-bottom: 9px;
                    width: 50%;
                }
            }
        }
    }
}

@media (max-width: 959px) {
   .footer{
        padding: 3em 2.5em;
        .contact { 
            .MuiListItem-gutters {
                width: 100% !important;
                padding-bottom: 27px !important;
                text-align: center !important;
            }
        }
        .spounLogo {
            &:first-child {
                .logoFooter {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    .logan {
                        font-size: 8px;
                    }
                }
            }
        }
        .footer-content {
            .footer-content-left {
                margin-bottom: 66px;
                p {
                   width: 100% !important;
                   text-align: justify;
                }
            }
            .footer-content-right {
                padding-top: 64px;
                .content {
                    text-align: center;
                    margin-left: 0 !important;
                }
            }            
        }
        .logoFooter {
            .logoFooter-box {
                position: absolute;
                display:flex;
                top: 0;
                left: 50%;
                transform: translate(-50%);
                div {
                    padding-top: 0;
                    &:first-child {
                        margin-right: 0;
                        img {
                            &:first-child {
                                width: 132px;
                                height: 34px;
                            }
                        }
                    }
                    img {
                        width: 167px;
                        height: 34px;
                    }
                }
            }
        }

        .spounLogo {
            &:first-child {
                margin-bottom: 14px;
            }
            &.footer-content {
                .footer-content-right {
                    .content {
                        margin-left: 8em;
                    }
                    .text-foot{
                        float: unset !important;
                        margin: auto
                    }
                }
            }
            .contact {
                .MuiListItem-gutters {
                    text-align: right;
                    padding-bottom: 9px;
                    width: 50%;
                }
            }
        }
    }
}