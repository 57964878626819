table{
    .fluctuations{
        &.increase{
          margin-left: 10px;
          position: relative;
          color: #2FCAAE;
          &::before{
            content: '';
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #2FCAAE;
            left: 10px;
            top: 8px;
          }
        }
        &.decrease{
            margin-left: 10px;
            position: relative;
            color: red;
            &::before{
              content: '';
              position: absolute;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid red;
              left: 10px;
              top: 8px;
            }
          }
      }
}