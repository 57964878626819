.latest-commentaries{
    .border{
        margin: 0% 6%;
        &:first-child{
            display: none;
        }
    }
    .content-commentary{
        padding: 0px 6% 20px 6%;
        .name-product{
            font-size: 16px;
            line-height: 22px;
            color: #558ED5;
            margin-top: 25px;
            @media (max-width: 767px){
                margin-bottom: 10px;
            }
        }
        .title-commentary{
            font-size: 18px;
            line-height: 25px;
            color: #000000;
            margin-top: 25px;
        }
        .date-commentary{
            font-size: 16px;
            line-height: 22px;
            color: #999999;
            text-align: right;
            margin-top: 25px;
            @media (max-width: 1280px){
                text-align: left;
            }
        }
        .swap-content{
            display: flex;
            width: 100%;
            @media (min-width: 767px) and (max-width: 1280px){
                margin-top: 40px;
            }
            @media (max-width: 1280px){
                flex-wrap: wrap-reverse;
            }
            @media (max-width: 767px){
                border-bottom: .5px solid #D5D5D5;
            }
        }
    }
    .btn{
        text-align: center;
        padding: 4% 0;
        .btnViewAll{
            background: #10253F;
            border-radius: 50px;
            color: #FFFFFF;
            padding: 15px 60px;
            text-transform: capitalize;
        }
    }
    
    .title-latest {
        padding: 2% 6% 0 6%; 
        color: #558ED5;
        font-size: 25px;
        line-height: 34px;
    }

   .title{
       padding: 2% 6% 0 6%;
        
       .titleBrand{
        font-size: 25px;
        line-height: 34px;
        color: #10253F;
       }
       .titleProduct{
        font-size: 25px;
        line-height: 34px;
        color: #10253F;
       }
       .titleTime{
        font-size: 16px;
        line-height: 34px;
        color: #000000;
        text-align: center;
        .mass{
            font-size: 14px;
            line-height: 19px;
            color: #999999;
        }
       }
   }
   .production{
    padding: 0% 6%;
    list-style: none;
    li{
        padding-bottom: 20px;
        .vitaminType{
            color: #000000;
            .resultProduct{
                .info{
                    .infoVitamin{
                        padding-right: 10px;
                        font-size: 16px;
                        line-height: 22px;
                        color: #558ED5;
                    }
                    .time{
                        color: #999999;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                .infoVitaminNone{
                    display: none;  
                }
                .borderNone{
                    display: none;  
                }
            }
            
            .infoPlace{
                font-size: 18px;
                line-height: 25px;
            }
            .product{
                .infoProduct{
                    padding-top: 10px;
                    .proVitamin{
                        font-size: 16px;
                        line-height: 18px;
                        color: #558ED5;
                        font-weight: bold;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .borderNone{
                     display: none;  
                }
                .number{
                    padding-top: 10px;
                    text-align: center;
                    .viewPrice{
                        font-size: 16px;
                        line-height: 22px;
                        color: #000000;
                        opacity: 0.2;
                    }
                    .numberBox{
                        position: relative;
                    }
                    .priceAndCurrency{
                        font-size: 16px;
                        line-height: 22px;
                        text-align: center;
                        color: #000000;
                    }
                    .dateMarketData{
                        font-size: 16px;
                        line-height: 22px;
                        color: #000000;
                        opacity: 0.5;
                    }
                    .increaseIcon{
                        position: relative;
                        display: inline-block;
                        &::after{
                            content: '';
                            position: absolute;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 5px solid #2FCAAE;
                            left: 0px;
                            top: 0px;
                        }
                    }
                    .decreaseIcon{
                        position: relative;
                        display: inline-block;
                        &::after{
                            content: '';
                            position: absolute;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid #B64A4A;
                            left: 0px;
                            top: 0px;
                        }
                    }
                }
            }
        }
    }
   }

}
@include breakpoint(md) { 
    .latest-commentaries{
        .border{
            display: none;
        }
        .title{
            padding: 2% 6%;
            .titleBrand{
             font-size: 25px;
             line-height: 34px;
             color: #10253F;
            }
            .titleTime{
                display: none;
            }
        }
        .production{
         padding: 0% 6%;
         list-style: none;
         li{
             .vitaminType{
                 color: #000000;
                 .resultProduct{
                    .info{
                        .infoVitamin{
                            display: none;
                        }
                        .time{
                            font-size: 14px;
                            line-height: 19px;
                            color: #999999;
                        }
                    }
                    .infoVitaminNone{
                        font-size: 16px;
                        line-height: 22px;
                        color: #333333;
                        display: block;  
                    }
                }
                
                 .infoPlace{
                     font-size: 18px;
                     line-height: 25px;
                     font-weight: 600;
                 }
                 .product{
                     .infoProduct{
                        padding-bottom: 10px;
                        .proVitamin{
                            font-size: 16px;
                            line-height: 18px;
                            color: #558ED5;
                            font-weight: bold;
                        }
                        .export{
                            opacity: 0.5;
                        }
                     }
                    .number{
                        padding-top: 10px;
                        text-align: center;
                     }
                 }
             }
         }
        }
         .subscribe{
             display: flex;
             flex-direction: column;
             text-align: center;
             background: #F9F9F9;
             padding: 5%;
             .subscribeQuestion{
                 font-size: 35px;
                 line-height: 48px;
                 color: #10253F;
             }
             .linkSubscribe{
                 color: #FFFFFF;
                 padding-top: 30px;
                 .subscribeButtonLink{
                     outline: none;
                     padding: 10px 50px;
                     text-decoration: none;
                     background: #10253F;
                     color: #FFFFFF;
                     border-radius: 50px;
                 }
             }
         }
     }
}
@include breakpoint(xm) { 
    .latest-commentaries{
    
        .title{
            padding: 2% 6%;
            .titleBrand{
             font-size: 25px;
             line-height: 34px;
             color: #10253F;
            }
            .titleProduct{
             
            }
        }
        .production{
         padding: 0% 6%;
         list-style: none;
         li{
             .vitaminType{
                 color: #000000;
                 .resultProduct{
                    .info{
                            .infoVitamin{
                            font-size: 16px;
                            line-height: 22px;
                            color: #558ED5;
                            }
                            .time{
                                color: #999999;
                            }
                        }
                    }
                
                 .infoPlace{
                     font-size: 18px;
                     line-height: 25px;
                     font-weight: 600;
                 }
                 .product{
                     .infoProduct{
                            padding-bottom: 10px;
                            .proVitamin{
                            font-size: 16px;
                            line-height: 18px;
                            color: #558ED5;
                            font-weight: normal;
                        }
                     }
                     
                     .number{
                         padding-top: 10px;
                         text-align: center;
                     }
                 }
             }
         }
        }
         .subscribe{
             display: flex;
             flex-direction: column;
             text-align: center;
             background: #F9F9F9;
             padding: 5%;
             .subscribeQuestion{
                 font-size: 35px;
                 line-height: 48px;
                 color: #10253F;
             }
             .linkSubscribe{
                 color: #FFFFFF;
                 padding-top: 30px;
                 .subscribeButtonLink{
                     outline: none;
                     padding: 10px 50px;
                     text-decoration: none;
                     background: #10253F;
                     color: #FFFFFF;
                     border-radius: 50px;
                 }
             }
             
         }
     }
}
@include breakpoint(960) {
    .latest-commentaries{
        .border{
            display: none;
        }
        .title{
            .titleProduct{
                display: none;
            }
        }
        .production{
            li{
                .vitaminType{
                    .resultProduct{
                        .borderNone{
                            display: block;
                            margin: 10px 0;      
                        }
                        .info{
                            .infoVitamin{
                                display: none;
                            }
                            .time{
                                color: #999999;
                            }
                        }
                        .infoVitaminNone{
                            display: block;  
                        }
                    }
                    .product{
                        .borderNone{
                            margin: 10px 0;
                            display: block;
                            width: 100%; 
                        }
                        .number{
                            .viewPrice{
                                text-align: left;
                            }
                        }
                        .padding0{
                            padding: 0!important;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(mobile) { 
    .latest-commentaries{
        .title-latest {
            padding: 24px 24px 0 24px;
        }
        .border {
            margin: 0 28px;
        }
        .title{
            padding: 14px 24px;
            .titleBrand{
             font-size: 25px;
             line-height: 34px;
             color: #10253F;
            }
            .titleProduct{
                display: none;
            }
            
        }
        .production{
            padding: 0 24px;
            list-style: none;
            li{
                .vitaminType{
                    color: #000000;
                    .resultProduct{
                        .info{
                            display: flex;
                            flex-direction: column;
                            .infoVitamin{
                                font-size: 16px;
                                line-height: 22px;
                                color: #558ED5;
                            }
                            .time{
                                color: #999999;
                            }
                        }
                    }
                    .infoPlace {
                        font-size: 18px;
                        line-height: 25px;
                    }
                    .product {
                        .infoProduct{
                            padding: 0;
                            .proVitamin{
                                font-size: 16px;
                                line-height: 18px;
                                color: #558ED5;
                                font-weight: normal;
                            }
                            .export{
                                color:#999999;;
                            }
                    }
                     
                    .number{
                        padding-top: 0;
                        text-align: center;
                    }
                }
             }
         }
        }
         .subscribe{
             display: flex;
             flex-direction: column;
             text-align: center;
             background: #F9F9F9;
             padding: 5%;
             .subscribeQuestion{
                 font-size: 35px;
                 line-height: 48px;
                 color: #10253F;
             }
             .linkSubscribe{
                 color: #FFFFFF;
                 padding-top: 30px;
                 .subscribeButtonLink{
                     
                     padding: 10px 50px;
                     text-decoration: none;
                     background: #10253F;
                     color: #FFFFFF;
                     border-radius: 50px;
                 }
             }
             
         }
     }
  }
