.container{
    height: 100vh;
    .account-box-logo {
        padding-top: 62px;
    }
    .form{
        min-height: 900px;
        display: flex;
        justify-content: center;
        .contentLeft{
            position: relative;
            .logoLogin{
                margin-top: 15%;
                background: url('../../asset/images/Web-logo-2.png');
                width: 184px;
                height: 32px;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            .formSignUp{
                padding-top: 1.3em;
                font-weight: 300;
                font-size: 25px;
                padding-bottom: 32%;
                form{
                    padding-top: 1.5em;
                    .fVAzGH {
                        margin: 10px 0;
                    }
                    .btn{
                        width: 100%;
                        color: white;
                        background: #10253F;
                        border-radius: 5px;
                        margin-top: 2%;
                        text-transform: capitalize;
                    }
                }
                .css-2b097c-container {
                    border-right: none;
                    z-index: 999;
                    .css-1a1lvae-control {
                        box-shadow: none;
                        &:hover {
                            border-color: transparent;
                        }
                    }
                }
                .custom-input-phone {
                    width: 100%;
                    height: 100%;
                    border: none;
                    margin-left: 0;
                    padding-left: 100px;
                    position: relative;
                    top: -3px;
                    .MuiInputBase-input {
                        border-left: 1px solid #D5D5D5;
                        padding: 0 10px;
                        border-radius: 0;
                    }
                }
                .linkSignup{
                    padding-top: 1.75em;
                    font-size: 15px;
                    .MuiGrid-root {
                        text-align: center;
                    }
                    a{
                        text-decoration: none;
                        font-size: 15px;
                        color: #10253F!important;
                        font-weight: 600;
                    }
                }
                
            }

            .Copyright{
                position: absolute;
                font-size: 14px;
                color: #999999;
                bottom: 3%;
            }
        }
        .sign_up-password {
            margin-bottom: 0;
        }
        .sign_up--note {
            display: block;
            margin-top: 8px;
            margin-bottom: 15px;
            font-size: 12px;
            line-height: 16px;
            color: #666;
        }
    }
    .imageLogin{
        min-height: 900px;
        background: url('../../asset/images/Bg1.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
        display: flex;
        justify-content: center;
        .content{
     
            position: absolute;
            height: 68px;
            //left: 158px;
            top: 290px;
    
            font-size: 50px;
            line-height: 50px;
            .lable_1{
                color: $color-woodblack;
            }
            .lable_2{
                padding-top: 10px;
                color: $color-lightblue;
            }
            .lable_3{
                padding-top: 10px;
                color: $color-woodblack;
                font-size: 30px;
                text-align: center;
            }
        }
    }
}

@include breakpoint(xl)   { 
    .container{
        height: 100vh;
        .form{
            min-width: 50%!important;
            .contentLeft{
                padding-left: 35px;
                .logoLogin{
                    //left: 115px;
                    top: 30px;
                }
                .formSignUp{
                    top: 60px;
                }
                .Copyright{
                    padding-top: 15%;
                    font-size: 14px;
                    color: #999999;
                    position: absolute;
                }
                .linkSignup{
                    top: 830px;
                    a{
                        color:black;
                        text-decoration: none;
                    }
                }
                
            }
        }
        .imageLogin{
            max-width: 50%!important;
            min-width: 50%!important;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../../asset/images/Bg2.jpg');
            object-fit: cover;
            .content{
                font-size: 35px;
                .lable_3{
                    font-size: 20px;
                }
            }
        }
    }
}

@include breakpoint(xm)   { 
    .container{
        .form{
            min-width: 50%!important;
            .contentLeft{
                padding-left: 25px;
            }
        }
        .imageLogin{
            max-width: 50%!important;
            min-width: 50%!important;
            background-position: 67% 60%;
            background-repeat: no-repeat;
            object-fit: cover;
        }
    }
}

@include breakpoint(xs)   { 
    .container{
        .form{
            min-width: 100%!important;
            .contentLeft{
                padding:0 150px;
                .logoLogin{
                }
                .formSignUp{
                    top: 60px;
                }
                .Copyright{
                    padding-top: 23%;
                }

                
            }
        }
        .imageLogin{
            display: none;
        }
    }
}
@include breakpoint(mobile)   { 
    .container{
        height: 120px;
        .form{
            min-width: 100%!important;
            .contentLeft{
                padding:0px 26px;
                .logoLogin{
                    top: 52px;
                    left: 20px;
                }
                .formSignUp{
                    top: 80px;
                    padding-bottom: 94px;
                    //width: 340px;
                    input{
                        width: 310px;
                    }
                    button{
                        width: 335px;
                    }
                    .makeStyles-StyledInput-130 {
                        width: auto;
                        .MuiInputBase-input {
                            width: 100%;
                        }
                    }
                    .makeStyles-inputContainer-131{
                        //width: 335px;
                    }
                    .linkSignup{
                        padding-top: 10px;
                       
                    }
                }

                .Copyright{
                    position: relative;
                    text-align: center;
                    padding-top: 0;
                    padding-bottom: 30px;
                }
                
            }
        }
        .imageLogin{
            display: none;
        }
    }
}