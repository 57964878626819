.about-page{
  max-width: 1400px;
  margin: auto;
  padding: 40px 0;
  .intro{
    text-align: center;
    color: #10253F;
    font-size: 20px;
    .intro-item{
      max-width: 798px;
      margin:auto;
      line-height: 27px;
      &:first-child {
        margin-bottom: 25px;
      }
      &:last-child {
        margin-bottom: 120px;
      }
    }
    .mrT_30{
      margin-top: 30px;
    }
    .tileAbout{
      max-width: 800px;
      margin:auto;
      font-size:35px;
      line-height: 48px;
      &:first-child {
        margin-bottom: 25px;
      }
      &:last-child {
        margin-bottom: 120px;
      }
    }
  }
  .img-data-sharing{
    width: 100%;
    height: auto;
    max-width: 441px;
  }
  .wrap-content-1{
    padding: 0 38px;
    .titile-bold{
      font-weight: bold;
      font-size: 35px;
      line-height: 48px;
      color: #10253F;
    }
    .sub-title{
      font-weight: 300;
      font-size: 35px;
      line-height: 48px;
      color: #000000;
    }
    .description{
      margin-top: 30px;
      font-size: 20px;
      line-height: 27px;
      text-align: justify;
      color: #10253F;
    }
    .read-more{
      font-size: 20px;
      line-height: 27px;
      color: #10253F !important;
      text-decoration: underline;
      text-transform: unset;
      &:hover{
        background: #FFFFFF;
      }
    }
  }
  .wrap-content-2{
    text-align: center;
    padding: 140px 200px;
    font-weight: 300;
    font-size: 35px;
    line-height: 48px;
    color: #000000;
  }
  .icons-about{
    text-align: center;
    .des{
      max-width: 330px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }
  }
}
.about-page-full{
  .wrap-content-3{
    flex: auto;
    .title-app{
      font-size: 35px;
      line-height: 48px;
      text-align: right;
      color: #10253F;
    }
    .des{
      margin-top: 15px;
      max-width: 620px;
      float: right;
      font-size: 25px;
      line-height: 34px;
      text-align: right;
      color: #000000;
    }
  }
  .wrap-content-4{
    .img-app{
      width: 100%;
      height: auto;
      max-width: 520px;
    }
  }
  .div-color{
    position: absolute;
    background: #FAFAFA;
    height: 580px;
    width: 100%;
    z-index: -1;
    transform: translateY(30%);
  }
  .wrap-content-5{
    margin-top: 80px;
    text-align: center;
    .title{
      font-size: 35px;
      line-height: 48px;
      color: #10253F;
    }
    .sub-title{
      font-size: 18px;
      line-height: 25px;
      color: #10253F;
    }
  }
}
.wrap-btn-subs{
  text-align: center;
  padding: 20px;
  margin-top: 75px;
  margin-bottom: 190px;
  .btn-subs{
    padding: 25px 200px;
    background: #10253F;
    border-radius: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF !important;
    text-decoration: none !important;
  }
  .btn-subs-now{
    padding: 15px 110px;
    background: #10253F;
    border-radius: 50px;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF !important;
    text-decoration: none !important;
  }
}
.clear-both{
  clear: both;
}
@include breakpoint(xl) { 
  .about-page{
    max-width: unset;
    padding: 40px 60px;
  }
}
@include breakpoint(xs) {
  .about-page{
    max-width: unset;
    padding: 40px 60px;
    .wrap-content-2{
      padding: 140px 145px;
    }
    .res-w-100{
      margin-top: 50px;
      flex-grow: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
    .res-w-grid{
      flex-grow: 0 !important;
      max-width: 50% !important;
      flex-basis: 50% !important;
    }
  }
  .about-page-full{
    .justy-flex-end{
      justify-content: flex-end;
      .wrap-content-3{
        max-width: 400px;
      }
    }
  }
}
@include breakpoint(mobile) {
  .about-page{
    padding: 40px 20px 20px 20px;
    .hide-grid-mb{
      display: none;
    }
    .wrap-content-1{
      max-width: 100%;
      padding: 0 11px;
      margin-left: 0;
      .titile-bold{
        font-size: 25px;
      }
      .sub-title{
        font-size: 25px;
        line-height: 34px;
      }
      .description{
        font-size: 16px;
        line-height: 22px;
      }
      .read-more{
        font-size: 16px;
      }
    }
    .mobile-100{
      flex-grow: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
    .wrap-content-2 {
      padding: 200px 35px 10px 35px;
      font-size: 25px;
    }
    .res-w-grid{
      margin-top: 50px;
      flex-grow: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }
  .about-page-full{
    .justy-flex-end{
      .wrap-content-3{
        margin-top: 50px;
        max-width: unset;
        .title-app{
          font-size: 25px;
          line-height: 34px;
        }
        .des{
          font-size: 20px;
          line-height: 27px;
        }
      }
    }
    .wrap-content-4{
      margin-bottom: 50px;
      text-align: center;
      .img-app{
        max-width: 285px;
      }
    }
    .wrap-content-5{
      .title{
        font-size: 25px;
      }
    }
    .mb-w-100{
      flex-grow: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
      background: #FAFAFA;
    }
    .padding-left-right{
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .wrap-btn-subs{
    padding: 20px 10px;
    .btn-subs{
      padding: 15px 130px;
    }
  }
}
