.category-and-countries{
  margin-top: 12px;
  min-height: 93px;
  .category{
    text-align: center;
    .btn-select-category{
      margin: 0 8px;
      &.active{
        background: rgba(16, 37, 63, 0.1);
      }
    }
  }
  .countries{
    padding: 0 !important;
    text-align: center;
    background: #F9F9F9;
    box-shadow: 0px 5px 25px #bebebe40;
    .btn-select-countries{
      border-radius: 0;
      margin: 0 8px;
      border-bottom: 1px solid #F9F9F9;
      &.active{
        border-bottom: 1px solid #10253F;
      }
    }
  }
  .scrolling-wrapper-flexbox {
    margin-bottom: 20px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .scrolling-wrapper-flexbox {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    .card {
      display: inline-block;
      flex: 0 0 auto;
      margin-right: 3px;
    }
  }
}
.market-comm{
  padding: 40px 150px;
  .defaut-height{
    min-height: calc(50vh - 99px);
  }
  .text-no-data-2{
    position: absolute;
    left: calc(45% - 35px);
    top: 200px;
    width: auto;
    padding: 0;
    .no-data{
      font-size: 20px;
      line-height: 27px;
      color: #999999;
    }
  }
  .subHeaderMarketComm{
    font-size: 20px !important;
    line-height: 27px !important;
    color: #666666 !important;
    margin-bottom: 15px;
    position: relative !important;
  }
  .date-comm{
    margin-right: 20px;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #999999;
  }
  .marketCommTitle{
    font-size: 35px;
    line-height: 48px;
    color: #10253F;
    line-height: 25px;
    margin-bottom: 65px;
    position: relative !important;
    z-index: 0;
  }
  .link-market{
    text-decoration: none;
    font-size: 16px !important;
    line-height: 22px;
    color: #000000 !important;
  }
  .Mui-selected{
    .link-market{
      color: #558ED5 !important;
    }
  }
  .text-sub-market{
    font-size: 16px;
    line-height: 22px;
    color: #6E6E6E;
  }
  .select-filter-mobile{
    display: none;
  }
  .load-more{
    margin-top: 40px;
    margin-left: 300px;
    color: #558ED5;
  }
  .select-analysis{
    border-left: 1px solid #D5D5D5;
    padding-left: 47px !important;
  }
}
@include breakpoint(xl) { 
  .market-comm{
    padding: 40px 45px;
  }
}
@include breakpoint(xs) {
  .market-comm{
    padding: 40px 60px;
    .hide-filter-mobile{
      display: none;
    }
    .grid-comm-mobile{
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: unset;
      .select-filter-mobile{
        display: flex;
        .select-category{
          width: 50%;
          margin-right: 10px;
        }
        .select-contries{
          width: 50%;
          margin-right: 5px;
        }
      }
    }
    .marketCommTitle{
      //text-align: center;
    }
    // .MuiListItem-gutters{
    //   display: flex;
    //   justify-content: center;
    // }
    .select-filter-mobile{
      display: block;
    }
    .load-more{
      margin-top: 40px;
      margin-left: 0;
      color: #558ED5;
    }
    .mobile-center-loader{
      text-align: center;
    }
  }
}
@include breakpoint(sm) { 
  .market-comm{
    padding: 40px 35px;
    .grid-comm-mobile{
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: unset;
      .select-filter-mobile{
        display: block;
        padding: 0;
        .select-category{
          width: 100%;
          margin-right: 5px;
        }
        .select-contries{
          width: 100%;
          margin-right: 5px;
        }
      }
    }
    .marketCommTitle {
      text-align: left;
      padding-left: 0;
      font-size: 25px;
    }
    .mb-pd-20{
      padding: 20px 0 !important;
    }
  }
}
@include breakpoint(mobile) {
  .category-and-countries{
    .category{
      padding: 10px 0;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin-bottom: 12px;
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px #F5F5F5;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar{
        height: 2px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #000000;
        border: 2px solid #737373;
      }
    }
    .countries{
      padding: 10px 0 !important;
      background: #F5F5F5;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(68, 67, 67, 0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar{
        height: 2px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #000000;
        border: 2px solid #737373;
      }
    }
  }
  .market-comm{
    padding: 20px 21px;
    .select-analysis{
      padding-left: 0 !important;
      border-left: 0;
      flex-grow: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }
  .mb-w-100{
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
// @include breakpoint(sm){
//   .market-comm{
//     padding: 40px 40px;
//     .mb-pd-20{
//       padding: 20px 35px !important;
//     }
//   }
// }
