.wrap-cookie{
  .bg-color{
    background: #10253F;
    color: #FFFFFF;
    padding: 40px 50px;
    font-size: 16px;
  }
  .box-btn-cookie {
    text-align: right;
    .btn-use-cookie{
      color: #FFFFFF !important;
      padding: 12px 70px;
      background: #558ED5;
      border-radius: 50px;
      text-transform: capitalize;
      &:hover{
        background: #558ED5;
      }
    }
  }
}
@media (max-width: 959px){
  .wrap-cookie{
    .box-btn-cookie {
      text-align: center;
      .btn-use-cookie {
        margin-top: 40px;
        float: unset;
      }
    }
  }
}
@include breakpoint(mobile) {
  .wrap-cookie{
    .content-text-cookie {
      text-align: justify;
    }
    .box-btn-cookie {
      text-align: center;
      .btn-use-cookie {
        margin-top: 40px;
        float: unset;
      }
    }
  }
}