// Variable overrides

// color
$color-white: #FFFFFF;
$color-black: #000000;
$color-green: #14DC94;
$color-woodsmoke: #15181D;
$color-shark: #1D2026;
$color-gray: #575962;
$color-boulder: #777777;
$color-salute: #2B2F37;
$color-blue: #3366FF;
$color-woodblack:#10253F;
$color-lightblue:#558ED5;

// font size
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-24: 24px;
$font-size-30: 30px;

//font family
$font-opensans: 'Open Sans', sans-serif;

//mouser
$pointer: pointer;