.container_fp_submited{
    height: 100vh;
    .form{
        min-height: 900px;
        display: flex;
        justify-content: center;
        position: relative;
        .contentLeft{
            position: relative;
            .logoLogin{
                margin-top: 20%;
                background: url('../../../asset/images/Web-logo-2.png');
                width: 184px;
                height: 32px;
                background-repeat: no-repeat;
            }
            .formOTP{
                font-weight: 300;
                font-size: 25px;
                width: 410px;
                padding-top: 35%;
                .noted{
                    padding-bottom:25px;
                    font-weight: 300;
                    font-size: 16px;
                }
                .btn{
                    background-color: #10253F;
                    color: white;
                    width: 100%;
                    margin-top: 2%;
                }
            }
            .linkSignup{
                padding-top: 10%;
                text-align: center;
                .time{
                    font-weight: 600;
                    color: #10253F;
                }
                .resend-OTP{
                    text-decoration: underline;
                    &:hover{
                        cursor: pointer;
                    }
                }
                a{
                    text-decoration: none;
                    font-size: 15px;
                    color: #10253F!important;
                    font-weight: 600;
                }
            }
            .Copyright{
                position: absolute;
                bottom: 3%;
                font-size: 14px;
                color: #999999;
            }
        }
        
    }
    .imageLogin{
        min-height: 900px;
        background: url('../../../asset/images/Bg1.jpg');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: cover;
        display: flex;
        justify-content: center;
        .content{
            top: 20%;
            position: absolute;
            height: 68px;
    
            font-size: 50px;
            line-height: 50px;
            .lable_1{
                color: $color-woodblack;
            }
            .lable_2{
                padding-top: 10px;
                color: $color-lightblue;
            }
            .lable_3{
                padding-top: 10px;
                color: $color-woodblack;
                font-size: 30px;
                text-align: center;
            }
        }
    }
}

@include breakpoint(xl)   { 
    .container_fp_submited{
        height: 100vh;
        .form{
            min-width: 50%!important;
            .contentLeft{
                padding-left: 35px;
                .logoforget{
                    //left: 115px;
                }
                .formforget_default{
                    top: 240px;
                }
                .linkSignup{
                    top: 610px;
                    a{
                        color:black;
                        text-decoration: none;
                    }
                }
                .Copyright{
                    font-size: 14px;
                    color: #999999;
                }
                
            }
        }
        .imageLogin{
            max-width: 50%!important;
            min-width: 50%!important;
            background: url('../../../asset/images/Bg2.jpg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
            .content{
                font-size: 35px;
                .lable_3{
                    font-size: 20px;
                }
            }
        }
    }
}

@include breakpoint(xm)   { 
    .container_fp_submited{
        height: 100vh;
        .form{
            min-width: 50%!important;
            .contentLeft{
                padding-left: 25px;
            }
        }
        .imageLogin{
            max-width: 50%!important;
            min-width: 50%!important;
            background-position: 67% 60%;
            background-repeat: no-repeat;
            object-fit: cover;
        }
    }
}

@include breakpoint(xs)   { 
    .container_fp_submited{
        height: 100vh;
        .form{
            min-width: 100%!important;
            .contentLeft{
                padding-left: 175px;
                .logoforget{
                    //left: 115px;
                }
                .formforget_default{
                    top: 240px;
                }
                .linkSignup{
                    top: 610px;
                    a{
                        color:black;
                        text-decoration: none;
                    }
                }
                
            }
        }
        .imageLogin{
            display: none;
        }
    }
}

@include breakpoint(mobile)   { 
    .container_fp_submited{
        height: 100vh;
        .form{
            min-width: 100%!important;
            .contentLeft{
                // display: flex;
                // justify-content: center;
                padding:0px 10px;
                .logoforget{
                    top: 52px;
                    left: 20px;
                }
                .formforget_default{
                    top: 153px;
                    width: 340px;
                        input{
                            width: 310px;
                        }
                        button{
                            width: 335px;
                        }
                        .makeStyles-inputContainer-127{
                            width: 335px;
                        }
                    
                }
                .linkSignup{
                    top: 527px;
                    a{
                        color:black;
                        text-decoration: none;
                    }
                }
                .Copyright{
                    font-size: 14px;
                    color: #999999;
                }
                
            }
        }
        .imageLogin{
            display: none;
        }
    }
}