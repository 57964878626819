.bg-header{
  .search-input {
      #icon-search {
        position: relative;
        top: -28px;
        right: -35px;
        width: 20px;
        height: 20px;
      }
  }
};