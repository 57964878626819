.prodcut-detail{
    padding-top: 3%;
    .MuiGrid-spacing-xs-1 {
        width: 100%;
    }
    .formSelect{
        .modelSelect{
            display: none;
        }
    }
    .content{
        .infoVitamin{
            .modalAndVitamin{
                .modalProduct{
                    display: none;
                }
                .title{
                    min-width: 80%;
                }
            }
            
            .vitamin{
                font-size: 35px;
                line-height: 48px;
                color: #10253F;
            }
            .currency{
                font-size: 16px;
                line-height: 22px;
                color: #999999;
            }
        }
        .number{
            .numberLeft{
                font-weight: bold;
                font-size: 45px;
                line-height: 61px;
                color: #10253F;
                .fluctuations{
                    &.increase{
                      margin-left: 10px;
                      position: relative;
                      color: #2FCAAE;
                      &::before{
                        content: '';
                        position: absolute;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 15px solid #2FCAAE;
                        left: 0px;
                        top: 20px;
                      }
                    }
                  }
                  .fluctuations{
                    &.decrease{
                      margin-left: 10px;
                      position: relative;
                      color:red;
                      &::before{
                        content: '';
                        position: absolute;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 15px solid red;
                        left: 0px;
                        top: 20px;
                      }
                    }
                  }
            }
            .numberRight{
                padding-top: 10px;
                color: #666666;
                font-size: 16px;
                line-height: 22px;
                .previous{
                    line-height: 22px;
                    padding-right: 40px;
                    
                }
                .fluc{
                    line-height: 22px;
                    padding-right: 21px;
                }
                .fluctuations{
                    &.increase{
                      margin-left: 10px;
                      position: relative;
                      color: #2FCAAE;
                      &::before{
                        content: '';
                        position: absolute;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid #2FCAAE;
                        left: 10px;
                        top: 8px;
                      }
                    }
                  }
                  .fluctuations{
                    &.decrease{
                      margin-left: 10px;
                      position: relative;
                      color: red;
                      &::before{
                        content: '';
                        position: absolute;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid red;
                        left: 10px;
                        top: 8px;
                      }
                    }
                  }
            }
        }
        .exportPrice{
            .time{
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #999999;
            }
            .country{
                font-size: 16px;
                line-height: 22px;
                color: #000000;
            }
            .export{
                font-size: 16px;
                line-height: 22px;
                color: #999999;
            }
        }
        .datetime{
            padding-top:30px;
            .picker{
                z-index: 1;
            }
            a{
                height: 40px;
                z-index: 1;
                span{
                    vertical-align: middle;
                }
                .rs-picker-toggle-value{
                    color: #000000;
                }
            }
            .btnChart{
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                .plus_and_min{
                    position: relative;
                    width: 80px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #D5D5D5;
                    box-sizing: border-box;
                    border-radius: 5px;
                    .line{
                        position: absolute;
                        transform: rotateX(45deg);
                        background-color: #D5D5D5;
                        width: 1px;
                        height: 100%;
                        left: 49%;
                        z-index: 99999;
                    }
                    .plus{
                        color: #666666;
                        font-size: 16px;
                        padding: 7px 0px 6px 19px;
                    }
                    .mins{
                        color: #666666;
                        font-size: 16px;
                        padding: 7px 0px 6px 15px;
                    }
                }
                .zoomm{
                    margin-left: 15px;
                    position: relative;
                    width: 40px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #D5D5D5;
                    box-sizing: border-box;
                    border-radius: 5px;
                    img{
                        position: absolute;
                        width: 13px;
                        height: 13px;
                        top:13px;
                        left: 13px;
                    }
                }
            }
           
        }
        .chart{
            padding-top: 10px;
            .rounded_chart{
                width: 100%;
                    .Component-root-251{
                    height: 325px !important;
                    width: 100%!important;
                }
            }
            // .MuiPaper-elevation1{
            //     box-shadow: none;
            // }
           
        }
    }
    .table{
        padding-bottom: 2%;
        .table_grid_1{
            // max-width: 7%;
        }
        .table_grid_2{
            // min-width: 80%;
            .history{
                font-size: 20px;
                line-height: 27px;
                color: #10253F;
            }
        }
        .table_grid_3{
            // max-width: 7%;
        }
    }
    .analysis{
        max-width: 90%;
    }
    .marketCom{
        padding-top: 2%;
        padding-bottom: 2%;
        .marketInfo{
            .loader{
                text-align: center;
            }
            .showMarketComm{
                width: 100%;
                cursor: pointer;
                padding: 15px;
                position: sticky;
                background: #F9F9F9;
                text-align: center;
                border-radius: 5px;
            }
            .enable{
                display: none;
            }
            .marketTitle{
                .title{
                    color: #10253F;
                    padding-bottom: 2%;
                    font-size: 20px;
                }
                .country{
                    .popover{
                        .MuiPaper-elevation8{
                            top: 0 !important;
                        }
                    }
                        display: flex;
                        justify-content: flex-end;
                        .selectCountry{
                            text-transform: none;
                            box-shadow: none;
                            background-color: white;
                            color:black !important;
                            &::after{
                                content: '';
                                position: absolute;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid #10253F;
                                right: 0px;
                                top: 17px;
                                // border-bottom: 5px solid black !important;
                            }
                            .icons-flag{
                                filter: invert(1);
                            }
                        }
                    // .selectCountry{
                        // box-shadow: none;
                        // background-color: white;
                    // }
                }
                
            }
            
            .date-comm{
                margin-right: 20px;
                font-size: 14px;
                line-height: 19px;
                text-align: right;
                color: #999999;
            }
            .marketCommTitle{
                font-size: 35px;
                line-height: 48px;
                color: #10253F;
                line-height: 25px;
                margin-bottom: 65px;
                position: relative !important;
                z-index: 0;
            }
            .link-market{
                text-decoration: none;
                font-size: 16px !important;
                line-height: 22px;
                color: #000000 !important;
            }
        }
           
    }
    
}
@include breakpoint(xl)   { 
    .prodcut-detail{
        padding-top: 3%;
        .formSelect{
            max-width: 12%;
            .modelSelect{
                display: block;
            }
            .select{
                display: none;
            }
        }
        .content{
           
            .infoVitamin{
                .modalAndVitamin{
                    .title{
                        max-width: 80%;
                    }
                    display: flex;
                    .modalProduct{
                    display: none;
                }
                }
                
                .vitamin{
                    font-size: 35px;
                    line-height: 48px;
                    color: #10253F;
                }
                .currency{
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
            }
            .number{
                .numberLeft{
                    font-weight: bold;
                    font-size: 45px;
                    line-height: 61px;
                    color: #10253F;
                    .fluctuations{
                        &.increase{
                          margin-left: 10px;
                          position: relative;
                          color: #2FCAAE;
                          &::before{
                            content: '';
                            position: absolute;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 15px solid #2FCAAE;
                            left: 0px;
                            top: 20px;
                          }
                        }
                      }
                }
                .numberRight{
                    padding-top: 10px;
                    color: #666666;
                    font-size: 16px;
                    line-height: 22px;
                    .previous{
                        line-height: 22px;
                        padding-right: 40px;
                        
                    }
                    .fluc{
                        line-height: 22px;
                        padding-right: 21px;
                    }
                    .fluctuations{
                        &.increase{
                          margin-left: 10px;
                          position: relative;
                          color: #2FCAAE;
                          &::before{
                            content: '';
                            position: absolute;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 5px solid #2FCAAE;
                            left: 10px;
                            top: 8px;
                          }
                        }
                      }
                }
            }
            .exportPrice{
                .time{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
                .country{
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                }
                .export{
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
            }
            .datetime{
                padding-top:30px;
            }
            .chart{
                padding-top: 10px;
                .rounded_chart{
                    width: 98%;
                        .Component-root-251{
                        height: 295px !important;
                        // width: 825px!important;
                        width: 100%!important;
                    }
                }
               
            }
    
        }
        .table{
            .table_grid_1{
                max-width: 7%;
            }
            .table_grid_2{
                min-width: 80%;
                .history{
                    font-size: 20px;
                    line-height: 27px;
                    color: #10253F;
                }
            }
            .table_grid_3{
                max-width: 7%;
            }
        }
        .marketCom{
            padding-top: 2%;
            .marketCom_child_1{
                max-width: 7%;
            }
            .marketCom_child_3{
                max-width: 7%;
            }
            .marketInfo{
                min-width: 80%;
                .title{
                    padding-bottom: 2%;
                }
                .date-comm{
                    margin-right: 20px;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: right;
                    color: #999999;
                }
                .marketCommTitle{
                    font-size: 35px;
                    line-height: 48px;
                    color: #10253F;
                    line-height: 25px;
                    margin-bottom: 65px;
                    position: relative !important;
                    z-index: 0;
                }
                .link-market{
                    text-decoration: none;
                    font-size: 16px !important;
                    line-height: 22px;
                    color: #000000 !important;
                }
            }
               
        }
        
    }
 }
 @include breakpoint(xs)   { 
    .prodcut-detail{
        padding-top: 3%;
        .formSelect{
            max-width: 7%;
            .modelSelect{
                display: none;
            }
            .select{
                display: none;
            }
        }
        .content{
            min-width: 80%;
            .infoVitamin{
                .modalAndVitamin{
                    .title{
                        min-width: 50%;
                            .vitamin{
                            font-size: 35px;
                            line-height: 48px;
                            color: #10253F;
                            }
                    }
                    .modalProduct{
                        display: flex;
                        justify-content: flex-end;
                        padding-top: 10px;
                    }
                    
                    .currency{
                        font-size: 16px;
                        line-height: 22px;
                        color: #999999;
                    }
                }

                
            }
            .number{
                .numberLeft{
                    font-weight: bold;
                    font-size: 45px;
                    line-height: 61px;
                    color: #10253F;
                    .fluctuations{
                        &.increase{
                          margin-left: 10px;
                          position: relative;
                          color: #2FCAAE;
                          &::before{
                            content: '';
                            position: absolute;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 15px solid #2FCAAE;
                            left: 0px;
                            top: 20px;
                          }
                        }
                      }
                }
                .numberRight{
                    padding-top: 10px;
                    color: #666666;
                    font-size: 16px;
                    line-height: 22px;
                    .previous{
                        line-height: 22px;
                        padding-right: 40px;
                        
                    }
                    .fluc{
                        line-height: 22px;
                        padding-right: 21px;
                    }
                    .fluctuations{
                        &.increase{
                          margin-left: 10px;
                          position: relative;
                          color: #2FCAAE;
                          &::before{
                            content: '';
                            position: absolute;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 5px solid #2FCAAE;
                            left: 10px;
                            top: 8px;
                          }
                        }
                      }
                }
            }
            .exportPrice{
                .time{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
                .country{
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                }
                .export{
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
            }
            .datetime{
                padding-top:30px;
            }
            .chart{
                padding-top: 10px;
                .rounded_chart{
                    width: 98%;
                        .Component-root-251{
                            height: 295px !important;
                        // width: 825px!important;
                        width: 100%!important;
                    }
                }
               
            }
    
        }
        .analysis{
            display: none;
        }
        .table{
            .table_grid_1{
                max-width: 7%;
            }
            .table_grid_2{
                min-width: 80%;
            }
            .table_grid_3{
                max-width: 7%;
            }
        }
        .marketCom{
            padding-top: 2%;
            .marketCom_child_1{
                max-width: 7%;
            }
            .marketCom_child_3{
                max-width: 7%;
            }
            .marketInfo{
                min-width: 80%;
                .title{
                    padding-bottom: 2%;
                }
                .date-comm{
                    margin-right: 20px;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: right;
                    color: #999999;
                }
                .marketCommTitle{
                    font-size: 35px;
                    line-height: 48px;
                    color: #10253F;
                    line-height: 25px;
                    margin-bottom: 65px;
                    position: relative !important;
                    z-index: 0;
                }
                .link-market{
                    text-decoration: none;
                    font-size: 16px !important;
                    line-height: 22px;
                    color: #000000 !important;
                }
            }
               
        }
        
    }
 } 
 @include breakpoint(mobile)   { 
    .prodcut-detail{
        padding-top: 3%;
        .formSelect{
            max-width: 7%;
            .modelSelect{
                display: none;
            }
            .select{
                display: none;
            }
        }
        .content{
            min-width: 93%;
            .infoVitamin{
                
                 .modalAndVitamin{
                    flex-wrap: wrap;
                    box-sizing: border-box;
                    flex-direction: column-reverse;
                    width: 100%;
                    .title{
                        flex-basis: 0;
                        .vitamin{
                        font-size: 35px;
                        line-height: 48px;
                        color: #10253F;
                        
                        }
                    }
                    
                    .modalProduct{
                        justify-content: flex-start;
                        flex-basis: 0;
                    }
                    
                }
                .currency{
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
            }
            .number{
                .numberLeft{
                    font-weight: bold;
                    font-size: 45px;
                    line-height: 61px;
                    color: #10253F;
                    .fluctuations{
                        &.increase{
                          margin-left: 10px;
                          position: relative;
                          color: #2FCAAE;
                          &::before{
                            content: '';
                            position: absolute;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 15px solid #2FCAAE;
                            left: 0px;
                            top: 20px;
                          }
                        }
                      }
                }
                .numberRight{
                    padding-top: 10px;
                    color: #666666;
                    font-size: 16px;
                    line-height: 22px;
                    .previous{
                        line-height: 22px;
                        padding-right: 40px;
                        
                    }
                    .fluc{
                        line-height: 22px;
                        padding-right: 21px;
                    }
                    .fluctuations{
                        &.increase{
                          margin-left: 10px;
                          position: relative;
                          color: #2FCAAE;
                          &::before{
                            content: '';
                            position: absolute;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 5px solid #2FCAAE;
                            left: 10px;
                            top: 8px;
                          }
                        }
                      }
                }
            }
         
            .exportPrice{
                .time{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
                .country{
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                }
                .export{
                    font-size: 16px;
                    line-height: 22px;
                    color: #999999;
                }
            }
            .datetime{
                padding-top:30px;
                .btnChart{
                    cursor: pointer;
                    display: none;
                }
            }
            .chart{
                overflow: hidden;
                .rounded_chart{
                    width: 100%;
                        .Component-root-251{
                            height: 295px !important;
                            width: 625px!important;
                            min-width: 360px;
                        // width: 100%!important;
                    }
                }
               
            }
           
    
        }
        .analysis{
            display: none;
        }
        .table{
            .table_grid_1{
                max-width: 7%;
            }
            .table_grid_2{
                min-width: 80%;
            }
            .table_grid_3{
                max-width: 7%;
            }
        }
        .marketCom{
            padding-top: 2%;
            .marketCom_child_1{
                max-width: 7%;
            }
            .marketCom_child_3{
                max-width: 7%;
            }
            .marketInfo{
                min-width: 80%;
                .title{
                    padding-bottom: 2%;
                }
                .date-comm{
                    margin-right: 20px;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: right;
                    color: #999999;
                }
                .marketCommTitle{
                    font-size: 35px;
                    line-height: 48px;
                    color: #10253F;
                    line-height: 25px;
                    margin-bottom: 65px;
                    position: relative !important;
                    z-index: 0;
                }
                .link-market{
                    text-decoration: none;
                    font-size: 16px !important;
                    line-height: 22px;
                    color: #000000 !important;
                }
            }
               
        }
        
    }
 } 
 .load_ding_commentaries {
     text-align: center;
 }