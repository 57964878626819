.nav-bar {
  flex-grow: 1;
  .rps-logo{
    @media (min-width: 1280px) and (max-width:1500px){
      flex-grow: 0 !important;
      max-width: 27% !important;
      flex-basis: 27% !important;
      display: flex;
      align-items: center;
    }
  }
  .MuiPaper-elevation4{
    box-shadow: none;
    border: 1px solid #F9F9F9;
    height: 87px;
  }
  .MuiToolbar-gutters{
    padding: 22px 120px 25px 120px !important;
    height: 100%;
  }
  .MuiAppBar-colorPrimary{
    background: #FFFFFF;
  }
  .line-vertical{
    background-color: #E3E3E3;
    width: 0.075rem;
    height: 35px;
  }
  .flex-menu{
    display: flex;
    align-items: center;
    margin-left: 25px;
    .header-link{
      //margin-right: 40px;
      padding: 0 10px;
      text-decoration: none;
      &:last-child{
        margin-right: 0;
      }
      .child-link{
        color: #000000;
        font-size: 16px;
        line-height: 22px;
        &.active{
          font-weight: bold;
          color: #10253F;
        }
      }
    }
  }
  .selectLanguage{
    margin-left: 33px;
    .MuiButton-label {
      font-weight: bold;
      font-family: Open Sans;
    }
    &::before{
      border-bottom: 0;
    }
  }
  .menu-mobile{
    display: none;
  }
  .logo {
    &:first-child{
      border-right: 1px solid #E3E3E3;
    }
  }
}
.logo {
  display: inline-block;
  > img{
    vertical-align: middle;
  }
  &:first-child{
    padding-right: 15px;
    position: relative;
    top: -2px;
  }
  &:nth-of-type(2){
    margin-top: 5px;
    margin-left: 15px;
    //margin-right: 35px;
  }
}
.fullList{
  overflow-y: auto;
  background: #10253F;
  padding: 45px 20px;
  .close-menu{
    position: absolute;
    top: 35px;
    right: 20px;
    color: #FFFFFF !important;
    font-size: 20px;
  }
  .select-lang-mobile{
    &.select-lang-mobile-logged {
      margin-top: 0;
    }
    margin-top: 50px;
    display: flex;
    justify-content: center;
    .selectLanguage{
      color: #FFFFFF !important;
      &::after{
        border-top: 5px solid #FFFFFF !important;
      }
      .icons-flag{
        filter: invert(1);
      }
    }
  }
  .logo-menu-mobile{
    .logo-mobile{
      margin-top: 30px;
    }
  }
  .d-select{
    margin-top: 50px;
  }
  .header-link-mobile{
    margin-top: 40px;
    .child-link{
      font-size: 16px;
      color: #FFFFFF !important;
      text-decoration: none;
      &.active {
        color: #558ED5 !important;
      }
    }
  }
  .MuiExpansionPanel-rounded{
    max-width: 125px;
    background: #10253F !important;
    margin: auto !important;
    box-shadow: none !important;
  }
  .MuiExpansionPanelSummary-root{
    color: #FFFFFF !important;
    padding: 0 !important;
    font-size: 16px !important;
  }
  .MuiMenu-list{
    color: #FFFFFF;
    min-width: 180px;
  }
  .list-language-mobile{
    display: block;
    .MuiListItem-button{
      color: #FFFFFF;
    }
    .item-lang-mobile{
      .filter-img{
        filter: invert(1);
      }
      max-width: 200px;
      padding-left: 70px;
      margin: auto;
    }
  }
}
.selectLanguage{
  background: transparent !important;
  box-shadow: none !important;
  .icons-flag{
    margin-right: 8px;
    font-size: 14px !important;
    font-weight: bold;
    .icon-item{
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
  &::after{
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #10253F;
    right: 0px;
    top: 15px;
  }
}
// .MuiPaper-elevation8{
//   top: 30px !important;
// }
.list-language{
  padding-top: 0 !important;
  min-width: 180px;
  .MuiListItemText-root {
    .MuiTypography-root {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .MuiListItemIcon-root{
    min-width: 30px;
  }
}
// .MuiPopover-paper{
//   margin-top: 65px !important;
// }
.MuiSelect-select{
  display: flex !important;
  align-items: flex-start;
}
.d-flex{
  display: flex;
  align-items: center;
}
.show{
  display: block !important;
  transition: .3;
}
.hide{
  display: none !important;
  transition: .3;
}
.accountButton {
  box-shadow: none !important;
  min-Width: 160px !important;
  border-Radius: 20px !important;
  color: #FFFFFF !important;
  background: #10253F !important;
  margin-left: -40px !important;
  text-transform: capitalize !important;
  margin-right: -80px;
}
.loginButton {
  box-shadow: none !important;
  min-Width: 135px !important;
  border-Radius: 20px !important;
  color: #FFFFFF !important;
  background: #10253F !important;
  margin-left: -40px !important;
  text-transform: capitalize !important;
}
.subscribeButton {
  box-shadow: none !important;
  min-width: 160px !important;
  color: #FFFFFF !important;
  background: #558ED5 !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  text-transform: capitalize !important;
}
.loginButtonMobile{
  min-Width: 135px !important;
  border-Radius: 20px !important;
  color: #10253F !important;
  background: #FFFFFF !important;
  margin-left: -40px !important;
  text-transform: capitalize !important;
}
.subscribeButtonMobile{
  min-width: 160px !important;
  color: #FFFFFF !important;
  background: #558ED5 !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  text-transform: capitalize !important;
}
.mt-4{
  margin-top: 4px !important;
}
.mt-7{
  margin-top: 7px !important;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right !important;
}
@include breakpoint(xl) {
  .nav-bar{
    .MuiToolbar-gutters{
      padding: 5px 45px !important;
    }
    .flex-menu{
      .about-menu{
        display: none;
      }
    }
  }
}
@media (min-width: 1441px) and (max-width: 1500px) {
  .nav-bar {
    .MuiToolbar-gutters {
      padding: 22px 86px 25px!important;
    }
    .flex-menu{
      .header-link{
      }
    }
  }
  .infoApp {
    padding: 5px 0 5px 86px;
  }
}
@media (min-width: 1200px) and (max-width: 1279px) {
  .nav-bar{
    .menu-xs-2{
      display: none;
    }
    .menu-xs-3{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .menu-mobile{
      display: flex;
      .icons-menu{
        cursor: $pointer;
        font-size: 26px;
        color: #000000;
        position: relative;
        top: -3px;
      }
      .link-home{
        color: #000000 !important;
        text-decoration: none;
        margin-right: 10px;
      }
    }
    .selectLanguage{
      display: none;
    }
    .accountButton{
      display: none;
    }
    .loginButton {
      display: none;
    }
    .subscribeButton {
      display: none;
    }
  }
}
@include breakpoint(xm) {
  .nav-bar{
    .menu-xs-2{
      display: none;
    }
    .menu-xs-3{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .menu-mobile{
      display: flex;
      .icons-menu{
        cursor: $pointer;
        font-size: 26px;
        color: #000000;
        position: relative;
        top: -3px;
      }
      .link-home{
        color: #000000 !important;
        text-decoration: none;
        margin-right: 10px;
      }
    }
    .selectLanguage{
      display: none;
    }
    .accountButton{
      display: none;
    }
    .loginButton {
      display: none;
    }
    .subscribeButton {
      display: none;
    }
  }
}
@include breakpoint(mobile) {
  .nav-bar{
    .MuiPaper-elevation4{
      height: 70px;
    }
    .MuiToolbar-gutters{
      padding: 5px 20px !important;
    }
    .logo{
      &:first-child {
        border-right: none;
        img {
          width: 76px;
          height: 22px;
        }
      }
      &.logo2 {
        margin-left: 6px;
      }
    }
    .line-vertical {
      display: none;
    }
    .menu-mobile{
      .link-home{
        display: none;
      }
    }
  }
  .fullList{
    width: auto;
    height: 100vh !important;
    .select-lang-mobile{
      margin-top: 50px;
      display: flex;
      justify-content: center;
      .selectLanguage{
        color: #FFFFFF !important;
        .icons-flag{
          filter: invert(1);
        }
      }
    }
  }
}
.img-20-15{
  width: 20px;
  height: 15px;
}